import React, { Component } from "react";
import { connect } from "react-redux";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading";
import {searchPropComSales} from "../../actions/propcomAction";
import { openModal } from "../../actions/modalAction";

import {
  Icon,
} from "semantic-ui-react";
import PropComSummary from './PropComSummary';


class PropComSaleSearchList extends Component {
  state = {
    pageO:'',
    id:'',
  };
  componentDidMount() {
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
      const {
        limitCS,
        offsetCS,
    } = this.props;
    this.props.searchPropComSales(values ,limitCS, offsetCS);
   
  }
  handleSearchPropComSale = () => {
    this.props.openModal("SearchMyPropComSaleModal");
  };

  
    //^^^^^^^^^^^^^^^^^^^^^^^^^
    onChange=(e) => {
      this.setState({ [e.target.name]: e.target.value });
    }
  
  //###########################################

  back = () => {
    const {
      limitCS,
      offsetCS,
      
      
  } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offsetCS === 0) {
      return;
    }

    this.props.searchPropComSales(values, limitCS, (offsetCS - limitCS));
    
    window.scroll(0,0)
  };

  advance = () => {
    const {
      countCS,
      limitCS,
      offsetCS,
      
      
  } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) ||{};
      if (offsetCS + limitCS > countCS) {
      return;
    }

    this.props.searchPropComSales(values, limitCS, (offsetCS + limitCS));

    window.scroll(0,0)
  };
//***************** */
handleGoToPage = () => {
  const {
    countCS,
    limitCS,
} = this.props;
  const values =
    (this.props.form !== undefined && this.props.form.values) ||{};
   // use Math.abs for strict equality ===  to work and safe guard against negative value from user
   if (Math.abs(this.state.pageO) === 0) {
    return; 
  }

const page=(Math.abs(this.state.pageO*limitCS) > countCS)?(Math.ceil(countCS/limitCS-1)):(Math.abs(this.state.pageO)-1)


  //const page = (this.state.pageO-1)
    this.props.searchPropComSales(values, limitCS, (page * limitCS));
    
    window.scroll(0,0)

};

//****************** */
  renderPaginator=()=> {
    if (this.props.propcomsCS.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offsetCS}
          limit={this.props.limitCS}
          count={this.props.countCS}
          //values={this.props.values}
        />
      );
    }
  }

  renderLoading=()=> {
    if (this.props.fetchingCS===true) {
      return (<Loading/>)
    }
  }



  render() {
  
    return (

      <div className='maindiv-820 '>
      
      
      <div> {/* main column start */}
      
      <div className='card-attached border-radius-top border-sb' style={{textAlign:'center',backgroundColor:'dodgerblue'}} >
        
      {/**/}
      <div style={{display:'flex',color:'white',padding:'5px'}}>
      
      <div
      style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
      onClick={this.handleSearchPropComSale}>
            <span className='icon-button border1' > <Icon name='search' /></span >
          </div>
      
      
      
      <div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
      <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
      {this.props.countCS} Commercial Property For Sale
      </div>
      </div>
      
      
      </div>
      {/**/}
      
        </div>
        {/*  */}
      
          
        <div className='card-attached border-sb pxy-05'  >
      
      {this.renderLoading()}
      {this.props.propcomsCS.map((item)=> (<PropComSummary key={item._id} propcom={item}/>))}
      
      
      </div>
      
      
      
      
      
      <div className='card-attached border-sb '  >
      
      <div className='disflex-paginator card-paginator media-600'>
      {this.renderPaginator()}
      <div className="disflex-paginator  " >
        <div className= 'font-paginator'>Go To Page</div>
        <form>
              <input
              className='inputPage'
                placeholder="number"
                name="pageO"
                type="number"
                value={this.state.pageO}
                onChange={this.onChange}
              />
              <span  className="btnNumber"  onClick={this.handleGoToPage} >
                Submit
              </span>
            </form>
      
              
      </div>
      <br/>
      </div>
      
      </div>
      {/*  */}
      
      
      <br/><br/><br/><br/>
      
      </div> {/* main column end */}
      {/* side column */}
      
      {/* side column end */}
      {/* side column display at bottom in mobile start */}
      
      {/* side column mobile end */}
      
      
      </div>
      
      
      
          );
    
    //@@@@
    
  }
}


const mapStateToProps = ({ propcoms,auth, form, }) => {
  const {  fetchingCS,limitCS, offsetCS, countCS, } = propcoms;
  
  
  return {
    fetchingCS,
    limitCS,
    offsetCS,
    countCS,
    form: form.PropComSaleSearch,
    propcomsCS: propcoms.propcomsCS,
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
 {searchPropComSales,openModal, }
)(PropComSaleSearchList);
