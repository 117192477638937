import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";


import {fetchUser,updateUser} from "../../actions/authAction";
import { withRouter } from "react-router-dom";

import { Segment, Form, Button, Header,  } from "semantic-ui-react";
import TextInput from "../form/TextInput";
//import TextArea from "../form/TextArea";
import DateInput from '../form/DateInput';
import SelectInput from "../form/SelectInput";
import { userStatus,userTypeWOption } from "./Data";
import {
  composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';
import {isValidEmail,isValidPhone,} from "../validator/revalidator";
//
const validate = combineValidators({
  uphone1:composeValidators(
    //isRequired('Phone1'),
    isValidPhone({}),

  )(),
  userId:isValidPhone({}),
  uphone2:isValidPhone({}),
  uemail:isValidEmail({}),
  uwhatsapp: isValidPhone({}),
  source:isRequired('Source') ,
});


class UserEdit extends Component { 
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchUser(id);
  }

  onSubmit = values => {
    const { id } = this.props.match.params;
    this.props.updateUser(id, values, this.props.history);
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    
    return (
      <div style={{maxWidth:'700px',margin:'auto'}}>

            <br/>
        <Segment
          textAlign="center"
          //attached="bottom"
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header
               // color="blue"
               content="Edit User"
              />
        </Segment>
        <div className="card-form ">
          <div className=" label" >


            <Form
              style={{ padding: 25 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >
                <Button
            color="teal"
            size="mini"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
          <br/><br/>

          <label htmlFor="">User's Name</label>
              <Field
                name="uname"
                type="text"
                component={TextInput}
                placeholder="User's Name"
              />
                <label htmlFor="">Phone1</label>
                <Field
                  name="uphone1"
                  type="text"
                  component={TextInput}
                  placeholder="Phone1"
                />
                <label htmlFor="">Phone2</label>
                <Field
                  name="uphone2"
                  type="text"
                  component={TextInput}
                  placeholder="Phone2"
                />
                
                <label htmlFor="">Whatsapp ID</label>
                <Field
                  name="uwhatsapp"
                  type="text"
                  component={TextInput}
                  placeholder="Whatsapp ID"
                />

              
              <label htmlFor="">User's Mobile as user ID</label>
              <Field
                name="userId"
                type="text"
                component={TextInput}
                placeholder="User's Mobile as user ID"
              />
              <label htmlFor="">User's Email</label>
              <Field
                name="uemail"
                type="email"
                component={TextInput}
                placeholder="User's Email"
              />
              <label htmlFor="">User Type</label>
              <Field
              //search={true}
              name="userTypeW"
              type="text"
              component={SelectInput}
              options={userTypeWOption()}
              placeholder="User Type"
            />
            <label htmlFor="">User's Status</label>
              <Field
              //search={true}
              name="userStatus"
              type="text"
              component={SelectInput}
              options={userStatus()}
              placeholder="User's Status"
            />
            {/*  */}
      <label htmlFor="">User Subscription Start On</label>
      <Field
        name="userSubscriptionStartOn"
        type="text"
        component={DateInput}
        placeholder="Started On"
        dateFormat="dd-MM-yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
      <label htmlFor="">User Subscription Expire On</label>
      <Field
        name="userSubscriptionExpireOn"
        type="text"
        component={DateInput}
        placeholder="Expired On"
        dateFormat="dd-MM-yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />



              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            </Form>
            <br/>
                        </div>
                        </div>
                        </div>
    );
  
}
}

export default  connect(
  (state) => {
    
    return { initialValues: state.auth.userD,userA: state.auth.userA }; // pull initial values from auth reducer
  },
  {fetchUser,updateUser}
)(
  reduxForm({
    form: "UserEdit", // a unique identifier for this form
     validate
  })(withRouter(UserEdit))
);
