import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import writtenNumber from "written-number";
import {fetchUsers} from "../../actions/authAction"
import {fetchReqResi,updateReqResi} from "../../actions/reqresiAction";
import { withRouter } from "react-router-dom";

import {
  Segment,
  Form,
  Button,
  Header,
} from "semantic-ui-react";
import TextInput from "../form/TextInput";
import TextArea from "../form/TextArea";
import SelectInput from "../form/SelectInput";


import {
  reqresiStatus, 
  requiredFor,
  propertyType,
  bedroom,
  bathroom,
  additionalRooms,
  furnishedStatus,
  entry,
  floorNo,
  locality,
  projectType,
  //booleanOption,
  
} from "./Data";
import {
  composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';
import {isValidEmail,isValidPhone,} from "../validator/revalidator";

const validate = combineValidators({
  phone1:composeValidators(
    isRequired('Phone1'),
    isValidPhone({}),

  )(),
  phone2:isValidPhone({}),
  email:isValidEmail({}),
  whatsapp: isValidPhone({}),
  name:isRequired('Name') ,
  propertyType:isRequired('Property Type') ,
  projectType:isRequired('Project Type') ,
  requiredFor:isRequired('Required For') ,
  creditUsed:isRequired('Credit Used') ,
  veiwLimit:isRequired('Veiw Limit') ,
  userS:isRequired('User Id') ,
  userName:isRequired("User's Name") ,
  reqresiStatus:isRequired('reqresi Status') ,
});



class ReqResiEdit extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchReqResi(id);
    if(this.props.usersForData.length <1){
      this.props.fetchUsers()
    }
  }
// ****
projectName = () => {
  return this.props.state.projectRs.projectRsForData.map(value => ({
    key: value.projectName,
    text: `${value.projectName}` ,
    value: value.projectName,
  }));
};
  onSubmit = values => {
    const { id } = this.props.match.params;
    this.props.updateReqResi(id, values, this.props.history);
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }
  //**** */
  
  userName = () => {
    return [{uname:'',userId:''},...this.props.usersForData].map(value => ({
      key: value.uname,
      text: `${value.uname} ( ${value.userId} )` ,
      value: value.uname,
    }));
  };
  userId = () => {
    return [{_id:'',userId:''},...this.props.usersForData].map(value => ({
      key: value._id,
      text: `${value._id} ( ${value.userId} )` ,
      value: value._id
    }));
  };
  //** */
  render() {
    const { budgetMin, budgetMax,areaCarpetMax,areaCarpetMin } =
      this.props.state.form &&
      this.props.state.form.ReqResiEdit !== undefined &&
      this.props.state.form.ReqResiEdit.values !== undefined &&
      this.props.state.form.ReqResiEdit.values;
    const { handleSubmit, pristine, reset, submitting } = this.props;



      return (
      <div style={{maxWidth:'700px',margin:'auto'}}>
          <Segment
          textAlign="center"
          //attached="bottom"
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header
               // color="blue"
               content="Edit Requirement for Purchase Residential Property"
              />
        </Segment>
        <div className="card-form ">
      <div className=" label" >

              <Form  style={{ padding: 25 }} onSubmit={handleSubmit(this.onSubmit)}>

              <Button
            color="teal"
            size="mini"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
              <Button
                size="mini"
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
              <br /><br />
              <label >Name</label>
              <Field
                name="name"
                type="text"
                component={TextInput}
                placeholder="Name"
              />
              <label >Phone1</label>
              <Field
                name="phone1"
                type="text"
                component={TextInput}
                placeholder="Phone1"
              />
              <label >Phone2</label>
              <Field
                name="phone2"
                type="text"
                component={TextInput}
                placeholder="Phone2"
              />
              <label >Email</label>
              <Field
                name="email"
                type="text"
                component={TextInput}
                placeholder="Email"
              />
              <label >Whatsapp ID</label>
              <Field
                name="whatsapp"
                type="text"
                component={TextInput}
                placeholder="Whatsapp ID"
              />
              <label >Required For</label>
              <Field
                name="requiredFor"
                placeholder="Required For"
                type="text"
                options={requiredFor()}
                //search={true}
                component={SelectInput}
              />
              <label >Property Type</label>
              <Field
                name="propertyType"
                placeholder="Property Type"
                type="text"
                options={propertyType()}
                //search={true}
                multiple={true}
                component={SelectInput}
              />
              <label >Bedroom</label>
              <Field
                name="bedroom"
                placeholder="Bedroom"
                type="text"
                options={bedroom()}
                //search={true}
                multiple={true}
                component={SelectInput}
              />
              <label >Bathroom</label>
              <Field
                name="bathroom"
                placeholder="Bathroom"
                type="text"
                options={bathroom()}
                //search={true}
                multiple={true}
                component={SelectInput}
              />
              <label >Additional Rooms</label>
              <Field
                name="additionalRooms"
                placeholder="Additional Rooms"
                type="text"
                options={additionalRooms()}
                //search={true}
                multiple={true}
                component={SelectInput}
              />
              <label >
                Minimum Carpet Area In Square Foot{" "}
                <span>
                  <b>
                    ({writtenNumber(areaCarpetMin, {
                      lang: "enIndian"
                    }).toUpperCase()}
                    ){" "}
                  </b>
                </span>
              </label>
              <Field
                name="areaCarpetMin"
                placeholder="Minimum Carpet Area"
                type="number"
                component={TextInput}
              />
              <label >
                Maximum Carpet Area In Square Foot{" "}
                <span>
                  <b>
                    ({writtenNumber(areaCarpetMax, {
                      lang: "enIndian"
                    }).toUpperCase()}
                    ){" "}
                  </b>
                </span>
              </label>
              <Field
                name="areaCarpetMax"
                placeholder="Maximum Carpet Area"
                type="number"
                component={TextInput}
              />

              <label >Furnished Status</label>
              <Field
                name="furnishedStatus"
                placeholder="Furnished Status"
                type="text"
                options={furnishedStatus()}
                //search={true}
                multiple={true}
                component={SelectInput}
              />

              <label >
                Minimum Budget{" "}
                <span>
                  <b>
                    ({writtenNumber(budgetMin, {
                      lang: "enIndian"
                    }).toUpperCase()}
                    ){" "}
                  </b>
                </span>
              </label>
              <Field
                name="budgetMin"
                placeholder="Minimum Budget"
                type="number"
                component={TextInput}
              />
              <label >
                Maximum Budget{" "}
                <span>
                  <b>
                    ({writtenNumber(budgetMax, {
                      lang: "enIndian"
                    }).toUpperCase()}
                    ){" "}
                  </b>
                </span>
              </label>
              <Field
                name="budgetMax"
                placeholder="Maximum Budget"
                type="number"
                component={TextInput}
              />


              <label >Entry</label>
              <Field
                name="entry"
                placeholder="Entry"
                type="text"
                options={entry()}
                //search={true}
                multiple={true}
                component={SelectInput}
              />
              <label >Floor No</label>
              <Field
                name="floorNo"
                placeholder="Floor No"
                type="text"
                options={floorNo()}
                //search={true}
                multiple={true}
                component={SelectInput}
              />
              <label >Project Type</label>
                <Field
                name="projectType"
                placeholder="Project Type"
                type="text"
                options={projectType()}
                //search={true}
                component={SelectInput}
                />
              <label >Complex/Project Name </label>

              <Field
                name="projectName"
                placeholder="Complex/Project Name"
                type="text"
                options={this.projectName()}
                search={true}
                multiple={true}
                component={SelectInput}
              />
              <label >Locality</label>
              <Field
                name="locality"
                placeholder="Locality"
                type="text"
                options={locality()}
                //search={true}
                multiple={true}
                component={SelectInput}
              />
              <label >Note/Comments</label>
              <Field
                name="note"
                placeholder="Note/Comments"
                type="text"
                component={TextArea}
                rows={3}
              />
              <label >Credit Used</label>
                <Field
                  name="creditUsed"
                  placeholder="Credit Used"
                  type="text"
                  component={TextInput}
                />
                <label >Veiw Limit</label>
                <Field
                  name="veiwLimit"
                  placeholder="Veiw Limit"
                  type="text"
                  component={TextInput} 
                />
        <label >User Id</label>
        <Field
        name="userS"
        placeholder=" User _id"
        type="text"
        search={true}
        component={SelectInput}
        options={this.userId()}
        />
        <label >User's Name</label>
        <Field
        name="userName"
        placeholder="User's Name"
        type="text"
        search={true}
        component={SelectInput}
        options={this.userName()}
        />
                {/*  */}
              <label >reqresi Status</label>
                <Field
                //search={true}
                name="reqresiStatus"
                type="text"
                component={SelectInput}
                options={reqresiStatus()}
                placeholder="reqresiStatus"
                />
                {/*  */}    


                <br />

                <Button
                  color="olive"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Submit
                </Button>
                <Button
                  color="orange"
                  type="button"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Clear Values
                </Button>
              </Form>
              <br/><br/><br/>
              </div>
              </div>
              </div>
      );

  }
}

export default connect(
  state => {
    return {
      state,
      userA: state.auth.userA,
      usersForData:state.auth.usersForData,
      initialValues: state.reqresis.reqresi,
      reqresi: state.reqresis.reqresi
    }; // pull initial values from account reducer
  },
  {fetchReqResi,updateReqResi,fetchUsers}
)(
  reduxForm({
    form: "ReqResiEdit", // a unique identifier for this form
     validate
  })(withRouter(ReqResiEdit))
);
