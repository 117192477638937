import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios';
import format from "date-fns/format";
import { Link, withRouter } from "react-router-dom";
import {fetchProjectR} from "../../actions/projectRAction";
import { openModal } from "../../actions/modalAction";

import {
  Button,
} from "semantic-ui-react";




class ProjectRDetail extends Component {
  state = {
    propSale:true,
    min:null ,
    max:null ,
    propSaleCount:null ,
    propRentCount:null ,
    modalOpenP: false,
  };

  componentDidMount = async ()  =>{
    const { id } = this.props.match.params;
    this.props.fetchProjectR(id);
      const res = await axios.post(`/api/getPricePropResi/${id}`);
     // const min = res.data.min[0].expectedPrice;
    //  const max = res.data.max[0].expectedPrice;
      this.setState({
         min : res.data.min[0] !== undefined && res.data.min[0].expectedPrice,
         max : res.data.max[0] !== undefined && res.data.max[0].expectedPrice,
         propSaleCount:res.data.propSaleCount ,
         propRentCount:res.data.propRentCount ,
      });

  }

  
//**************** */
handleOpenModalImage = (id,imageOf) => {   
  this.props.openModal("ShowImagesForEditModal" , {id:id,imageOf:imageOf});
};
//^^^^^^^^^^^^^^^^^^
handleOpenModalVideo = (id,imageOf) => {   
  this.props.openModal("ShowVideoForEditModal" , {id:id,imageOf:imageOf});
};
//****** */

  //********************************* */

  render() {
     const { open,  } = this.state;
    if (!this.props.projectR) {
      return (
        <div><span style={{marginLeft: '200px',fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
    const {
      projectR: {
        reraNo,
        imagePath,
        projectType,
        propertyTypeProj,
        category,
        projectName,
        address,
        locality,
        // subCity,
         city,
        // state,
        // pincode,
        //locationOnMap,
        configuration,
        towers,
        units,
        status,
        possession,
        ageOfConstruction,
        totalFloors,
        liftsInTheTower,
        amenitiesBasic,
        // amenitiesConvSec,
        // amenitiesEntSoc,
        // amenitiesServ,
        // amenitiesSportFit,
        // amenitiesEco,
        //description,
        creditRequireForAd,
				creditRemainToLock,
				creditRequireToLock,
        adAllowedToDealers,
        idsOfDealerAllowedToAd,
        _id
      }
    } = this.props;
        
    //******************
			const renderAddImageButton = () =>
      this.props.userA !== null &&
      (this.props.userA.userType === "admin") ? (
      <Button
        // basic
        inverted
        floated="right"
        size="mini"
        color="orange"
        type="button"
        as={Link}
        to={`/activity/AddImageVideo/${_id}`}
      >
        Add Image
      </Button>
      ) : (
      <div></div>
      );
  //^^^^^^^^^^^^^^^
    
      
    //******************
    const price = (value) => {
       var val = Math.abs(value)
      if (val >= 10000000) {
        val = (val / 10000000).toFixed(2) + ' Cr';
      } else if (val >= 100000) {
        val = (val / 100000).toFixed(2) + ' Lac';
      }
      /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
      return val;
    }

    
    const renderpropertyType = () => {
      return propertyTypeProj.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} <span style={{color:'blue'}}>|</span></b>
          </span>
        );
      });
    };
    const renderconfiguration = () => {
      return configuration.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} <span style={{color:'blue'}}>|</span></b>
          </span>
        );
      });
    };

    const renderamenitiesBasic = () => {
      return amenitiesBasic.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} <span style={{color:'blue'}}>|</span></b>
          </span>
        );
      });
    };
    const renderidsOfDealerAllowedToAd = () => {
      return idsOfDealerAllowedToAd.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} <span style={{color:'blue'}}>|</span></b>
          </span>
        );
      });
    };
    // const renderamenitiesConvSec = () => {
    //   return amenitiesConvSec.map(item => {
    //     return (
    //       <span key={item}>
    //         {" "}
    //         <b>{item} |</b>
    //       </span>
    //     );
    //   });
    // };
    // const renderamenitiesEntSoc = () => {
    //   return amenitiesEntSoc.map(item => {
    //     return (
    //       <span key={item}>
    //         {" "}
    //         <b>{item} |</b>
    //       </span>
    //     );
    //   });
    // };
    // const renderamenitiesServ = () => {
    //   return amenitiesServ.map(item => {
    //     return (
    //       <span key={item}>
    //         {" "}
    //         <b>{item} |</b>
    //       </span>
    //     );
    //   });
    // };
    // const renderamenitiesSportFit = () => {
    //   return amenitiesSportFit.map(item => {
    //     return (
    //       <span key={item}>
    //         {" "}
    //         <b>{item} |</b>
    //       </span>
    //     );
    //   });
    // };
    // const renderamenitiesEco = () => {
    //   return amenitiesEco.map(item => {
    //     return (
    //       <span key={item}>
    //         {" "}
    //         <b>{item} |</b>
    //       </span>
    //     );
    //   });
    // };
    
  //******************
    const poss = (Date.parse(possession) < Date.now())? "Immediate":<span>{ format(new Date(possession), "dd-MM-yyyy")}</span>
//*******************************************************
return (
      <div>
      <div className='maindiv-820'>


      <div>
      <div style={{boderRadius:'2px',marginBottom:'0px'}} >
      <img
      className= 'yimg'
      src={`${imagePath}`}
    //  src={require('../landing/img/showcase.jpg')}
      alt="Display_image" width="100%" height="300"
      
      />
</div>

      
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > {projectName}{' '}{price (this.state.min)}~{price (this.state.max)}</span>
	</div>

	</div>

  <div className='card-attached border-sb pxy-20'  >
{/*  */}
<span
 onClick={()=>this.handleOpenModalImage(_id,'ProjectR')}
 className="icon-button"
 >
 <img className= 'yimg ' src='/imageAddIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 {/*  */}
 <span
 onClick={()=>this.handleOpenModalVideo(_id,'ProjectR')} 
 className="icon-button"
 >
 <img className= 'yimg ' src='/videoViewIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 {/*  */}
        <Button.Group size="mini" floated="right">

        

        {renderAddImageButton()}
          <Button
            // basic
            inverted
            floated="right"
            size="mini"
            color="green"
            type="button"
            as={Link}
            to={`/projectR/ProjectREdit/${_id}`}
          >
            Edit
          </Button>
          
        </Button.Group>
        <br />
        <br />
        <div> <h3 className='border-bottom'>Project Detail</h3> </div>
<div >
{reraNo && (
<div className='row'>
<div className='side'>
<b>RERA No.</b>
</div>
<div className='main'>
<b>{reraNo}</b>
</div>

</div>
)}
</div>         
        <div >
         {projectName && (
           <div className='row'>
             <div className='side'>
               <b>Project Name</b>
             </div>
             <div className='main'>
               <b>{projectName}</b>
             </div>

           </div>
         )}
       </div>
       <div >
       {address && (
         <div className='row'>
           <div className='side'>
             <b>Address</b>
           </div>
           <div className='main'>
             <b>{address}{' '}</b>
           </div>

         </div>
       )}
     </div>
     <div >
     {locality && (
       <div className='row'>
         <div className='side'>
           <b>Locality</b>
         </div>
         <div className='main'>
           <b>{locality}</b>
         </div>

       </div>
     )}
   </div>
   <div >
     {city && (
       <div className='row'>
         <div className='side'>
           <b>City/District</b>
         </div>
         <div className='main'>
           <b>{city}</b>
         </div>

       </div>
     )}
   </div>
   <div >
   {projectType && (
     <div className='row'>
       <div className='side'>
         <b>Project Type</b>
       </div>
       <div className='main'>
         <b>{projectType}</b>
       </div>

     </div>
   )}
 </div>
 <div >
 {category && (
   <div className='row'>
     <div className='side'>
       <b>Category</b>
     </div>
     <div className='main'>
       <b>{category}</b>
     </div>

   </div>
 )}
</div>
   <div >
   {configuration &&
     configuration.length > 0 && (
     <div className='row'>
       <div className='side'>
         <b>Configuration</b>
       </div>
       <div className='main'>
         {renderconfiguration()}
       </div>

     </div>
   )}
   </div>
   <div >
   {propertyTypeProj &&
     propertyTypeProj.length > 0 && (
     <div className='row'>
       <div className='side'>
         <b>Property Type</b>
       </div>
       <div className='main'>
         {renderpropertyType()}
       </div>

     </div>
   )}
   </div>
   <div >
   {status && (
     <div className='row'>
       <div className='side'>
         <b>Status</b>
       </div>
       <div className='main'>
         <b>{status}</b>
       </div>

     </div>
   )}
 </div>
 <div >
 {possession && (
   <div className='row'>
     <div className='side'>
       <b>Possession</b>
     </div>
     <div className='main'>
       <b>{poss}</b>
     </div>

   </div>
 )}
</div>
<div >
{ageOfConstruction && (
 <div className='row'>
   <div className='side'>
     <b>Age Of Construction</b>
   </div>
   <div className='main'>
     <b>{ageOfConstruction}</b>
   </div>

 </div>
)}
</div>
<div >
{liftsInTheTower && (
 <div className='row'>
   <div className='side'>
     <b>Lifts In The Tower</b>
   </div>
   <div className='main'>
     <b>{liftsInTheTower}</b>
   </div>

 </div>
)}
</div>
<div >
{towers && (
 <div className='row'>
   <div className='side'>
     <b>Total Towers</b>
   </div>
   <div className='main'>
     <b>{towers}</b>
   </div>

 </div>
)}
</div>
<div >
{units && (
 <div className='row'>
   <div className='side'>
     <b>Total Units</b>
   </div>
   <div className='main'>
     <b>{units}</b>
   </div>

 </div>
)}
</div>
<div >
{totalFloors && (
 <div className='row'>
   <div className='side'>
     <b>Total Floors</b>
   </div>
   <div className='main'>
     <b>{totalFloors}</b>
   </div>

 </div>
)}
</div>

<div >
{amenitiesBasic &&
 amenitiesBasic.length > 0 && (
 <div className='row'>
   <div className='side'>
     <b>Amenities</b>
   </div>
   <div className='main'>
     {renderamenitiesBasic()}
   </div>

 </div>
)}
</div>

        <div >
				{creditRequireForAd >0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Credit Require For Ad</b>
					</div>
					<div className='main'>
					  <b>{creditRequireForAd}</b>
					</div>

				  </div>
				)}
				</div>
				
				<div >
				{creditRequireToLock >0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Credit Require To Lock</b>
					</div>
					<div className='main'>
					  <b>{creditRequireToLock}</b>
					</div>

				  </div>
				)}
				</div>
				
				<div >
				{creditRemainToLock >0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Credit Remain To Lock</b>
					</div>
					<div className='main'>
					  <b>{creditRemainToLock}</b>
					</div>

				  </div>
				)}
				</div>

        <div >
				{adAllowedToDealers && (
				  <div className='row'>
					<div className='side'>
					  <b>adAllowedToDealers</b>
					</div>
					<div className='main'>
					  <b>{adAllowedToDealers}</b>
					</div>

				  </div>
				)}
				</div>

        <div >
{idsOfDealerAllowedToAd &&
 idsOfDealerAllowedToAd.length > 0 && (
 <div className='row'>
   <div className='side'>
     <b>idsOfDealerAllowedToAd</b>
   </div>
   <div className='main'>
     {renderidsOfDealerAllowedToAd()}
   </div>

 </div>
)}
</div>



      </div>
      
      
      </div>


      </div>


      </div>

      //**********************************



    );

//*************************************************************




  }
}
function mapStateToProps(state) {

  return {
    userA: state.auth.userA,
    projectR: state.projectRs.projectR,
  };
}

export default connect(
  mapStateToProps,
  {fetchProjectR,openModal}
)(withRouter(ProjectRDetail));
