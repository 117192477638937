const projectNameO = [
  '',
  'DDA SfS Flats Cat-I & II Pocket 1, Sector 1',
  'DDA Sfs Flats Cat- II Pocket 2, Sector 1',
  'Aadi Apartment Pocket 3, Sector 1',
  'DDA Sfs Flats Cat- II Pocket 1, Sector 2',
  'DDA Sfs Flats Pocket 2, Sector 2',
  'Ekta Apartment Pocket 1 & 2, Sector 3',
  'Adarsh Apartment Pocket 16, Sector 3',
  'Harmony Apartment Pocket 1, Sector 4',
  'DDA Sfs Flats Cat- II & III Pocket 1, Sector 5',
  'DDA Sfs Flats Cat- II & III Pocket 1, Sector 6',
  'DDA Sfs Flats Cat- I & II Pocket 2, Sector 6',
  'DDA Sfs Flats Cat- II & III Pocket 1, Sector 7',
  'DDA EHS Flats Expandable Pocket 2, Sector 7',
  'DDA Sfs Flats Cat- II & III Pocket 1, Sector 9',
  'DDA Sfs Flats Cat- II & III Pocket 2, Sector 9',
  'DDA Sfs Flats Cat- I & II Pocket 1, Sector 10',
  'Surbhi Apartment Pocket 1, Sector 11',
  'DDA Sfs Flats Cat- II Pocket 2, Sector 11',
  'DDA Sfs Flats Cat- II Pocket 3, Sector 11',
  'Sun View Apartment Pocket 4, Sector 11',
  'Gangotri Apartment Pocket 1, Sector 12',
  'Dwaradheesh Apartment Pocket 2, Sector 12',
  'Indraprastha Apartment Pocket 3, Sector 12',
  'Shubham Apartment Pocket 4, Sector 12',
  'DDA Housing Complex Pocket 5, Sector 12',
  'DDA Mig Flats Pocket 6, Sector 12',
  'Rehayashi Welfare Pocket 7, Sector 12',
  'Golden Heights Pocket 8, Sector 12',
  'Sarvodaya Apartment Pocket 8, Sector 12',
  'Netaji Subhash Apartment Mig & Expandable Flats  Pocket 1, Sector 13',
  'Rosewood Apartment Pocket A, Sector 13',
  'Metro View Apartment Pocket B, Sector 13',
  'Radhika Apartment Pocket 1, Sector 14',
  'Om Apartment Pocket 2, Sector 14',
  'DDA LIG Flats Pocket 3, Sector 14',
  'Kautilya Apartment Pocket B, Sector 14',
  'Aashirwad Apartment,Janta Flats Pocket 3C, Sector 16B',
  'DDA LIG Flats Pocket 3C, Sector 16B',
  'Sarva Hit Apartment Pocket A, Sector 17',
  'Shri Keshav Kunj Apartment Pocket D, Sector 17',
  'Peepal Apartment Pocket E, Sector 17',
  'Vijay Veer Awas Apartment Pocket A, Sector 18',
  'Pink Apartment Pocket B, Sector 18',
  'Rose Apartment Pocket B, Sector 18',
  'Atulya Apartment, Multi Storey, Sector 18B',
  'Platinum Heights, Multi Storey, Sector 18B',
  'Samridhi Apartment, Multi Storey, Sector 18B',
  'Golf Link Residency, Multi Storey, Sector 18B',
  'Shri Awas Apartment L&T FLATS, Sector 18B',
  'Bharat Vandana Apartment Pocket 1, Sector 19',
  'Green View Apartment, Pocket 2, Sector 19',
  'Akshar Dham Apartment Mig & Expandable Flats  Pocket 3, Sector 19',
  'Sanskriti Apartment Pocket B, Sector 19',
  'DDA Sfs Flats Cat- II & III Pocket 1, Sector 22',
  'MIG & Expandable Flats  Pocket 1, Sector 23',
  'DDA LIG Flats, Sector 26',
  
    'Celestial Heights ( Aarti )',
    'Pragya',
    "Joy Apartment (The Delhi Transporter's)",
    'Anmol Apartments (The Bhagwan Shree Gomteshwarji)',
    'Ashadeep Apartment',
    'Mandakini Apartments',
    'Mahalaxmi Apartments',
    'NPSC Society',
    'The Nav Nirman',
    'The S. B. Youth',
    'Godhuli Senior Citizen Home',
  
    'Heritage Tower (Sawan)',
    'Sansad Vihar',
    'Aastha Kunj (Progressive Enclave)',
    'National Apartment',
    'The Vishrantika',
    'Graces Apartment (Batuk Ji)',
    'Welcomgroup',
    'Balaji',
    'Gauri Ganesh Apartments (Himachali)',
    'Air India Apartment',
    'Kairali Apartments',
    'Prodyogiki Apartments',
  
    'The Bahawal Pur',
    'Kailash Apartment',
    'Neelachal Apartment',
    'Umiya Sadan (Patel)',
    'The New Jai Bharat',
    'Aakriti Apartments (Civil Supplies)',
    'Din Apartments',
    'South Delhi Apartments',
    'IES Apartments (IES Officers)',
    'Pushpanjali Apartment (New Sweet Home)',
    'PNB Apartments',
    'Sarve Satyam Apartments',
    'Om Satyam Apartments',
    'Hum Sub Apartments',
    'Sanchar Vihar',
    'DPS Apartments',
    'Aishwaryam Apartments (ECIL)',
    'Shree Badrinath Apartments',
    'Dakshinayan (Delhi EPDP)',
    'Sanghamitra Apartments',
    'Ratnakar Apartments',
    'Bank Apartments (The Bank Niwas)',
    'Chanakya Apartments',
    'Panchsheel Apartment (External Affairs Employees)',
    'Anant Apartments',
    'Anamika Apartments (Shree New Anamika)',
    'Antriksh Apartments (DDA Engineers)',
    'New Jyoti',
    'Paarijat (Nav Puneet)',
    'Ispatika Apartments',
    'Saheta',
    'Kalyani Apartment (Jai Mata Kalyani)',
    'Shri Ram Apartment',
    'Defence Officers Apartments (Rajasva)',
    'United',
    'Green Heavens (SAIL)',
    'Rajasthan Apartment',
  
    'Shri Ganinath',
    'Vivekanand Apartment',
    'Mansarovar Apartments (The SSC Employees)',
    'Kesarwani',
    'Lovely Home',
    'Mother Apartments (The Mother Dairy Employees)',
    'Palam Apartments',
    'Jhelum (Arorvansh)',
    'Jawahar Apartment (Jawahar Lal)',
    'Shree Krishna Apartments (Meerabai)',
    'JDM Apartment ( Jai Diba Maa )',
    'Hind Apartments',
    'Navin Apartments',
    'Apoorva Apartment',
    'Dhauladhar Apartments (The Himachal Dhauladhar)',
    'Jaimaa Apartments',
    'Management Apartments (Management Alumni)',
    'Shakti Apartments',
    'The New Priyadarshini Apartments',
    'Eden Tower Apartment',
    'Himachal Apartments',
  
    'Vrindavan Apartments',
    'Guru Apartments',
    'Badhwar Apartments',
    'Kaveri Apartments',
    'Swami Dayanand Apartments',
    'The Shivlok',
    'Gyan Shakti Apartments',
    'Katyayani Apartment',
    'U.F. Apartment (United Friends)',
    'New Ashiana Apartment',
    'Sahara Apartment (Mahila PMSW)',
    'Rudra Apartments',
    'Diamond Square Apartments',
    'The Palms (Shubh Laxmi)',
    'Youngsters',
    'Surya Apartments',
    'Great India Apartment (Great Capital)',
    'Vasundhra Apartments',
    'Aakash Ganga',
    'IIP Admin Apartment',
    'Sanmati Kunj Apartment (The Sanmati)',
    'Aastha Apartment (Veg Sanchar Vihar)',
    'R. D. Apartment',
    'Mayank Mansion',
    'Anusandhan',
    'Sabka Ghar',
    'Som Apartment',
    'Manglik Apartment',
    'Prakriti Apartment (IIPA)',
    'Param Puneet',
    'Kamakshi Apartments (Grih Mantralya Employees)',
    'True Friends Apartments',
    'Bahawalpur Apartments',
    'Kalka Apartments',
    'Shivalik Apartments (Indian Bank Staff)',
    'Princess Park Apartment (Adarsh Purwal Vaish)',
    'Vidya Sagar Apartments (Rajat Jain)',
    'Skylark Apartment',
    'Daffodils Apartments',
    'Shree Balaji Apartment',
    'Unique Apartments',
    'Adarsh Arya Apartments',
    'Santosh Apartments',
    'Karur Apartment',
    'Hahnemann Enclave',
  
    'Shriniketan',
    'The Young Aheria',
    'Rashi Apartments',
    'Harsukh Apartments',
    'Kamal Vihar',
    'Satisar Apartments',
    'Brahma Apartment',
    "The Residency (St. Ann's) ",
    'Nayantara Apartment ( CBIP )',
    'Arjun Apartment',
    'Evergreen Apartment',
    'Sri Agrasen Apartment',
    'Air Force & Nawal Officers Enclave',
    'Happy Home (Rafi Ahmed Kidwai)',
    'Shree Ganesh Apartments',
    'Sargodha Apartments',
    'Krishna Kunj',
    'St. Columbas Apartment',
    'Arunachal Apartment ( Nehru )',
    'Siddhartha Kunj Apartment',
    'Media Society (The Media)',
    'Crown Apartments',
    'Meghdoot Apartment',
    'Shiv Bhole Apartments',
    'Dwarka Apartment (The Sharma Friends)',
    'Shruti Apartments',
    'The Shree Radha Krishna (Shri Krishna Garden)',
  
    'Paradise Apartments',
    'Milennium Apartments',
    'Shree Radha Apartments',
    'Bhrigu Apartment (K.K.)',
    'Royal Residency (Wtp.Be)',
    'Ganpati Apartment (Sumit Vihar)',
    'Manzil Apartment',
    'Sarve Sanjhi Apartments',
    'Gayatri Apartments',
    'Park Royal Apartments (Shiv Shankar)',
    'Sunview Apartment (Manocha Vihar)',
    'Munirka Apartments',
    'Sukh Sagar Apartments (The Sukhi Parivar)',
    'Krishna Apartments (Suraksha Sadan)',
    'Home Living Apartment',
    'Azad Hind',
    'Shivalika Apartment',
    'Mount Everest',
  
    'Hilansh Apartments',
    'Dharam Vihar',
    'Rashtrapati BCA Apartment',
    'Rajnigandha',
    'Amba Apartment',
    'HMM Employees Apartment',
    'Pragjyotishpur Apartments',
    'Chandanwari Apartment',
    'Panchsheel Apartments (The Gandhi Ashram)',
    'Shiv Shakti',
    'Bandhu Vihar Apartments',
    'I.I.T. Engineers Apartment',
    'Prerna Apartments',
    'Godrej Apartments',
    'Saral Apartment',
    'Shakuntalam Apartment',
    'Maitri Apartments (Indian AO & E)',
    'Fakhruddin Apartments',
    'Nanda Devi Apartment',
    'Supriya Apartments',
    'Divya Apartments',
    'New Adarsh Apartments',
    'Bharat Petroleum Corporation Apartment',
    'Apna Villa Apartments',
    'Mass Apartments',
    'Nuovo Apartments',
    'Mann Bhawan Apartment(Diesel Shed)',
    'Gayatri Apartment (Airliners)',
    'Cosmos Apartment',
    'DMP Apartment',
    'Prabhavi Apartment',
    'Rohit Apartments',
    'Suruchi Apartment',
    'Shama Apartments',
    'New Cosmopolitan Apartment',
    'Madhur Jiwan Apartments (Mother Dairy)',
    'Sanskriti Apartments (The Great Layalpur)',
    'Vinayak Apartments',
    'Thiruvizha Apartments',
    'The Eligible',
    'Pacific Apartments',
    'Baroda House Apartments',
    'Saksham Apartments (Niji)',
    'Elephanta Heights (The Elephant Head)',
    'Manchahat Apartments',
  
    'Shahjahanabad',
    'Rama Apartments (Dr RMLH & NHE)',
    'Palm Green Apartment (IAL Cargo )',
    'Gulmohar Apartments (The Arihant Sidhartha)',
    'Spring Valley Apartments (A G Bros)',
    'Appu Enclave',
    'The Gold Croft',
    'Raman Vihar',
    'Gokul Apartment (Apni)',
    'Pragiti Apartments',
    'Shiam Apartment',
    'The Sri Durga Apartment',
    'Whispering Greens (Sapna Ghar)',
    'Seema Apartments',
    'Ashoka Enclave',
    'Mk Residency (The Modest Ketki)',
    'IDC Apartment',
    'Yash Apartments ( The Chinyot )',
    'Heritage Apartments (Young Professional)',
    'Sadbhavana Apartments',
  
    'Navsanjivan',
    'Vidyut Apartment',
    'Vimal Apartment',
    'Ishwar Apartments',
    'Sahyadri Apartments',
    'Shri Hari Apartments (The Southern)',
    'Friends Circle Apartments',
    'Ashoka Apartment ( Defence L&CS )',
    'Upkari Apartments',
    'Brindavan Garden',
    'Aashirwad Apartment (Universal Brotherhood)',
    'K M Apartments',
    'New Arohi Apartments',
    'Shivam Apartment (Vikram Nagar)',
    'Abhiyan Apartments',
    'Park View Apartments',
    'Mohinder Apartments (The Dhan Pothowar)',
    'Shivani Apartments',
    'The Kunj Vihar (The Plazzio)',
    'Trimurti Apartments (The Anjuman)',
    'Sant Sunder Dass Ji',
    'Highland Apartments (The Bharat Jagariti)',
    'The New Rajput Apartment',
    'Classic Apartment (Bhairwa Bharti)',
    'Maharaja Saini Apartment',
    'Kanaka Durga Apartment',
    'The Sunny Valley',
  
    'Gulistan Residency',
    'DJA Apartment (Delhi Journalists Association)',
    'Rose Valley Apartment (Ravi Shankar)',
    'Ayudh Vihar (Ordnance Factories Officers)',
    'Swarup Sadan Apartment',
    'Shabad',
    'Mahabhadra Kali',
    'White Rose',
    'Durga Pooja Apartment',
  
    'Belur Heights',
    'Crescent Apartments (Nistads)',
    'Majestic Apartments (Chinar)',
    'The Excellence (Seth Vihar)',
    'Satyam',
    'The Samrat Ashoka Enclave',
    'Hamdam Apartment',
    'Bharat Apartments (Bharat Petroleum)',
    'Karuna Vihar',
    'Hare Krishna Valley (The Peoples Conservative)',
    'Engineers Apartment (Consulting Engineers)',
    'Janaksar Apartment ( Janak )',
    'Raj Vihar ( Raj Resi )',
    'IRCON Employees',
    'New Rashtriya',
    'Manokamna Apartments',
    'Krishna Residency (Vijay)',
    'Dharam Apartment',
    'Prem Milan Apartment',
  
    'The Delhi State (N.E.F.)',
    'Chitrakoot Dham',
    'Jan Vikas Apartment',
    'Best Paradise (The Bhartiya)',

    'Best Residency (Shri Sanmati)',
    'Golf Green Apartments (Roopvilla)',
    'Palm Court Apartments (The Gulshan-E-Iqbal)',
    'Golf View Apartments (Saptaparni)',
    'Nishat Apartments',
    "White House Residency (The Sleuth's)",
    'Lords Apartments',
    'Soloman Height',
    'Arvind Apartment',
    'Krishna Garden (The N.T.P.C Employees)',
    'Orchid Vally (Railway Line Staff)',
    'Om Apartment',
    'Kohinoor Residency (Chandralok)',
    'Sea Show',
  
    'Bhagwati Apartment',
    'Park Royal Residency (Vasundhara)',
    'Beverly Park ( Jaypee )',
    'The Naval Technical Officers',
    'Aero View Heights (Guru Ramdas)',
    'Nav Sansad Vihar',
    'Mahavir Apartments',
    'Garden Estate Apartment (Jai Bhawani)',
    'Sri Vinayak Apartment (Quetta)',
    'Veena Residency',
    'DG(S) Apartment',
    'Janaki Apartment',
    'Sadbhavna Apartments (Him Hit)',
    'Chitrakoot Apartment',
    'Himalayan Residency',
    'Classic Apartment (The Aimo)',
    'Udyog Vihar Apartment (U & V)',
    'Shubham Apartments (NPCC Employees)',
    'Dream Apartments (The Z.A. Nagar )',
    'Keshav Kunj (SBI Karamchari )',
    'Maharani Aventi Bai (Silver Oak)',
    'Delhi Apartments',
    'Bank Vihar Apartments',
    'Jagaran Apartments',
    'Green Valley Apartment',
    
    'New Kanchanjunga Apartments',
    'New Millennium Apartments ( Rao )',
    'Philips Tower',
    'IFCI Apartment ( IFCI Employees )',
    'Airlines Apartment',
    'Vishwas Apartment',
    'Harmony Apartment',
    'Vedanta (Dabbas)',
    'Navrattan Apartments',
    'Manisha Tower',
    'Green Tower (Union RME)',
    'Chopra Apartment',
    'Shaman Vihar',    
    'Sheetal Vihar',
    'Prabha Apartment',
    'Ramkrishan Apartment',
    'Dwarka Dham Apartments (Mahajan Shree)'
  ];
  
  

export const projectName = values => {
  return projectNameO.map(value => ({ key: value, text: value, value: value }));
};
