const projectNameO = [

  'Vardhman Market DDA,Sector 2',
  'Vardhman Central Market, Sector 3',
  'LSC,	Pankaj Chamber, Sector 4',
  'Chetan Arena Complex, Sector 4 LSC',

    'Vikas Surya Plaza, Plot 1, Sector 4 Main',
  	'Manish Twin Plaza, Plot 2, Sector 4 Main',
  	'Manish Twin Plaza, Plot 3, Sector 4 Main',
  	'Pankaj Plaza, Plot 4, Sector 4 Main',
  	'Malik Plaza, Plot 5, Sector 4 Main',
  	'Vardhman Jay Pee Plaza, Plot 6, Sector 4 Main',
  	'Vardhman Jay Pee Plaza, Plot 7, Sector 4 Main',
  	'V J P Plaza, Plot 8, Sector 4 Main',
  	'Vikas Surya Galaxy, Plot 9 Sector 4 Main,',
  	'Durga Plaza, Plot 10, Sector 4 Main',
  	'Manish Abhinav Plaza, Plot 11, Sector 4 Main',
  	'Krishna Plaza, Plot 12, Sector 4 Main',
    'Vardhman Bee Pee Plaza, Plot 1, Sector 5 Main',
  	'Aggarwal Tower, Plot 2, Sector 5 Main',
  	'Aggarwal Mall, Plot 3, Sector 5 Main'	,
  	'Vardhman Sudharshan Plaza, Plot 4, Sector 5 Main'	,
  	'Manish Abhinav Plaza, Plot 5, Sector 5 Main',
  	'HL Square, Plot 6, Sector 5 Main'	,
  	'Arjun Plaza, Plot 7, Sector 5 Main',
  	'R G Complex, Plot 8, Sector 5 Main'	,
  	'Manish Link Road Plaza, Plot 9, Sector 5 Main',
  	'Manish Link Plaza, Plot 10, Sector 5 Main'	,
  	'Aggarwal Central Plaza, Plot 11, Sector 5 Main'	,
  	'Bhagwati Plaza, Plot 12, Sector 5 Main'		,
  	'Manish Mega Plaza, Plot 13, Sector 5 Main'	,
  	'HL Arcade, Plot 14, Sector 5 Main'	,
  	'Raj Lakshmi Plaza, Plot 15, Sector 5 Main',
  	'Pankaj Arcade, Plot 16, Sector 5 Main'		,
    'Vardhman Key Point Plaza ,Sector 6 LSC',
  	'Aggarwal Dwarka Plaza ,Sector 6 LSC'		,
  	'Shivam Plaza ,Sector 6 LSC'		,
  	'Sd Plaza ,Sector 6 LSC'		,
  	'Vardhman Plaza ,Sector 6 LSC'		,
    'DDA LSC Market ,Sector 6 LSC'	,
    'Pankaj Plaza, Plot 1, Sector 6 Main',
  	'Odean Plaza, Plot 2, Sector 6 Main',
  	'Vardhman Plaza, Plot 3, Sector 6 Main',
  	'Aditya Complex, Plot 4, Sector 6 Main'	,
  	'Mefcons Plaza, Plot 5, Sector 6 Main'	,
  	'Sagar  Plaza, Plot 6, Sector 6 Main'	,
  	'Pankaj Plaza 1st, Plot 7, Sector 6 Main',
  	'Pankaj Plaza 2nd, Plot 8, Sector 6 Main'	,
  	'Pankaj Plaza 3rd, Plot 9, Sector 6 Main',
  	'Pankaj Plaza 4th, Plot 10, Sector 6 Main'	,
  	'Wadhwa Plaza, Plot 11, Sector 6 Main'		,
  	'Vardhman Airport Plaza 1st, Plot 12, Sector 6 Main',
  	'Bansal Plaza, Plot 13, Sector 6 Main',
  	'Vardhman Airport Plaza 2nd, Plot 14, Sector 6 Main',
  	'Supreme Plaza, Plot 15, Sector 6 Main'	,
  	'Supreme Plaza, Plot 16, Sector 6 Main'	,
    'Manish Abhinav Plaza, Sector 9',
  	'Vardhman Dheesh Plaza, Plot 1, Sector 10 Main',
  	'Antriksh Plaza, Plot 2, Sector 10 Main',
  	'Vardhman Plaza, Plot 3, Sector 10 Main'	,
  	'Aditya Plaza 2nd, Plot 4, Sector 10 Main',
  	'Wadhwa Plaza, Plot 5, Sector 10 Main',
  	'Aditya Complex, Plot 6, Sector 10 Main'	,
  	'Manish Plaza, Plot 7, Sector 10 Main'	,
  	'Anshul Plaza, Plot 8, Sector 10 Main'	,
  	'Pankaj Arcade 1st, Plot 9, Sector 10 Main',
  	'Manish Plaza 2nd, Plot 10, Sector 10 Main',
  	'Aggarwal Plaza, Plot 11, Sector 10 Main',
  	'Manish Plaza 3rd, Plot 12, Sector 10 Main',
  	'Odean Plaza, Plot 13, Sector 10 Main',
  	'Apra Dwarka Plaza 2nd, Plot 14, Sector 10 Main',
  	'Classic Airport, Plot 15, Sector 10 Main',
  	'Pankaj Arcade 2nd, Plot 16, Sector 10 Main',
  	'Manish Gauri Plaza, Plot 17, Sector 10 Main',
  	'Wadhwa Complex, Plot 18, Sector 10 Main'	,
  	'Manish Royal Plaza 5th, Plot 19, Sector 10 Main',
  	'Manish Royal Plaza 2nd, Plot 20, Sector 10 Main',
  	'Apra Dwarka Plaza 1st, Plot 21, Sector 10 Main',
  	'Krishna Plaza, Plot 22, Sector 10 Main',
    'Golokdham, Sector 10 LSC',
    'DDA, LSC Market,Sector 10 ',
    'Manish Market,Sector 11 LSC',
  	'Krishna Plaza 3rd, Plot 1, Pocket 4, Sector 11',
  	'HL Wings, Plot 2, Pocket 4, Sector 11',
  	'Sudeep Plaza, Plot31, Pocket 4, Sector 11',
  	'Pankaj Arcade 1st, Plot 4, Pocket 4, Sector 11',
  	'Pankaj Arcade 2nd, Plot 5, Pocket 4, Sector 11',
  	'Pankaj Arcade 3rd, Plot 6, Pocket 4, Sector 11',
  	'Pankaj Arcade 4th, Plot 7, Pocket 4, Sector 11' ,
  	'Centric Plaza, Plot 8, Pocket 4, Sector 11',
  	'Bimal Plaza, Plot 9, Pocket 4, Sector 11',
  	'Aggarwal Square, Plot 10, Pocket 4, Sector 11',
  	'Tirupati Plaza, Plot 11, Pocket 4, Sector 11',
  	'Manish Corner Plaza, Plot 1, Sector 11 Main',
  	'Manish Twin Plaza, Plot 2, Sector 11 Main',
  	'Manish Twin Plaza 2nd, Plot 3, Sector 11 Main',
  	'Akash Tower, Plot 4, Sector 11 Main',
  	'Vardhman Dee Cee Plaza, Plot 5, Sector 11 Main',
  	'Vardhman Dee Cee Plaza 2nd, Plot 6, Sector 11 Main',
  	'Vardhman Dee Cee Plaza 3rd, Plot 7, Sector 11 Main',
  	'Vikas Surya Arcade, Plot 8, Sector 11 Main',
  	'Manish Location Plaza, Plot 1, Sector 12 Main',
  	'HL Gleria, Plot 2, Sector 12 Main',
  	'Krishna Plaza 2nd, Plot 3, Sector 12 Main',
  	'Aggarwal Chamber, Plot 4, Sector 12 Main',
  	'Krishna Mall, Plot 5, Sector 12 Main',
  	'Aggarwal Arcade, Plot 6, Sector 12 Main',
  	'Vikas Surya Tower, Plot 7, Sector 12 Main',
  	'Krishna Tower, Plot 8, Sector 12 Main',
  	'HL Plaza, Plot 9, Sector 12 Main',
  	'Vardhman Gee Dee Plaza, Plot 10, Sector 12 Main',
  	'Aggarwal Plaza, Plot 11, Sector 12 Main',
  	'Vardhman Sunder Plaza, Plot 12, Sector 12 Main',
  	'Pankaj Plaza, Plot 13, Sector 12 Main',
  	'Raj Lakshmi Plaza, Plot 14, Sector 12 Main',
  	'Aggarwal Plaza, Plot 15, Sector 12 Main',
    'Manish Chamber 1st, Pocket A, Sector 12',
	'Manish Chamber 2nd, Pocket A, Sector 12',
	'Manish Chamber 3rd, Pocket A, Sector 12',
	'Manish Chamber 4th, Pocket A, Sector 12',
	'Pankaj Galaxy 1st, Pocket A, Sector 12',
	'Pankaj Galaxy 2nd, Pocket A, Sector 12',
    'Manish Metro Plaza 1st, Plot 1, Pocket 5, Sector 12',
  	'Manish Metro Plaza 2nd, Plot 2, Pocket 5, Sector 12',
  	'Manish Metro Plaza 3rd, Plot 3, Pocket 5, Sector 12',
  	'Manish Metro Plaza 4th, Plot 4, Pocket 5, Sector 12',
  	'Pankaj Central Plaza, Plot 5, Pocket 5, Sector 12',
  	'Malik Buildcon Plaza 2nd, Plot 6, Pocket 5, Sector 12',
  	'Vardhman Parsad Plaza, Plot 1, Pocket 6, Sector 12',
  	'Malik Build Con Plaza 1st, Plot 2, Pocket 6, Sector 12',
  	'Best Arcade, Plot 3, Pocket 6, Sector 12',
  	'Manish Metro Plaza 5th, Plot 4, Pocket 6, Sector 12',
  	'Manish Metro, Plot 5, Pocket 6, Sector 12',
  	'Krishna Tower 2, Plot 1, Pocket 7, Sector 12',
  	'Ashish Tower, Plot 2, Pocket 7, Sector 12',
  	'Shokeen Plaza, Plot 3, Pocket 7, Sector 12',
  	'Malhan Falcon Plaza, Plot 4, Pocket 7, Sector 12',
  	'Malhan Plaza, Plot 5, Pocket 7, Sector 12',
  	'Banhof Plaza, Plot 6, Pocket 7, Sector 12'	,
  	'Pankaj Plaza, Plot 7, Pocket 7, Sector 12'	,
  	'Aggarwal Square Plaza, Plot 8, Pocket 7, Sector 12',
  	'Aggarwal Complex Plaza, Plot 9, Pocket 7, Sector 12',
  	'Vardhman Banhof Plaza, Plot 10, Pocket 7, Sector 12',
	'City Center Mall, Sector 12'	,
	'Eros Mall, Sector 14',
	'Vegas Mall, Sector 14'	,
	'LSC Market, Sector 17',
	'Vardhman Mall, Sector 19',
	'Marbel Market, Sector 20',
	'Car Market, Sector 20'	,
	'HL Hub, Sector 20'	,
	'Manish Global Mall, Sector 22',
	'DDA LSC Market, Sector 22',
	'Vardhman Mall, Sector 23',

  
   
  'DDA SfS Flats Cat-I & II Pocket 1, Sector 1',
  'DDA Sfs Flats Cat- II Pocket 2, Sector 1',
  'Aadi Apartment Pocket 3, Sector 1',
  'DDA Sfs Flats Cat- II Pocket 1, Sector 2',
  'DDA Sfs Flats Pocket 2, Sector 2',
  'Ekta Apartment Pocket 1 & 2, Sector 3',
  'Adarsh Apartment Pocket 16, Sector 3',
  'Harmony Apartment Pocket 1, Sector 4',
  'DDA Sfs Flats Cat- II & III Pocket 1, Sector 5',
  'DDA Sfs Flats Cat- II & III Pocket 1, Sector 6',
  'DDA Sfs Flats Cat- I & II Pocket 2, Sector 6',
  'DDA Sfs Flats Cat- II & III Pocket 1, Sector 7',
  'DDA EHS Flats Expandable Pocket 2, Sector 7',
  'DDA Sfs Flats Cat- II & III Pocket 1, Sector 9',
  'DDA Sfs Flats Cat- II & III Pocket 2, Sector 9',
  'DDA Sfs Flats Cat- I & II Pocket 1, Sector 10',
  'Surbhi Apartment Pocket 1, Sector 11',
  'DDA Sfs Flats Cat- II Pocket 2, Sector 11',
  'DDA Sfs Flats Cat- II Pocket 3, Sector 11',
  'Sun View Apartment Pocket 4, Sector 11',
  'Gangotri Apartment Pocket 1, Sector 12',
  'Dwaradheesh Apartment Pocket 2, Sector 12',
  'Indraprastha Apartment Pocket 3, Sector 12',
  'Shubham Apartment Pocket 4, Sector 12',
  'DDA Housing Complex Pocket 5, Sector 12',
  'DDA Mig Flats Pocket 6, Sector 12',
  'Rehayashi Welfare Pocket 7, Sector 12',
  'Golden Heights Pocket 8, Sector 12',
  'Sarvodaya Apartment Pocket 8, Sector 12',
  'Netaji Subhash Apartment Mig & Expandable Flats  Pocket 1, Sector 13',
  'Rosewood Apartment Pocket A, Sector 13',
  'Metro View Apartment Pocket B, Sector 13',
  'Radhika Apartment Pocket 1, Sector 14',
  'Om Apartment Pocket 2, Sector 14',
  'DDA LIG Flats Pocket 3, Sector 14',
  'Kautilya Apartment Pocket B, Sector 14',
  'Aashirwad Apartment,Janta Flats Pocket 3C, Sector 16B',
  'DDA LIG Flats Pocket 3C, Sector 16B',
  'Sarva Hit Apartment Pocket A, Sector 17',
  'Shri Keshav Kunj Apartment Pocket D, Sector 17',
  'Peepal Apartment Pocket E, Sector 17',
  'Vijay Veer Awas Apartment Pocket A, Sector 18',
  'Pink Apartment Pocket B, Sector 18',
  'Rose Apartment Pocket B, Sector 18',
  'Atulya Apartment, Multi Storey, Sector 18B',
  'Platinum Heights, Multi Storey, Sector 18B',
  'Samridhi Apartment, Multi Storey, Sector 18B',
  'Golf Link Residency, Multi Storey, Sector 18B',
  'Shri Awas Apartment L&T FLATS, Sector 18B',
  'Bharat Vandana Apartment Pocket 1, Sector 19',
  'Green View Apartment, Pocket 2, Sector 19',
  'Akshar Dham Apartment Mig & Expandable Flats  Pocket 3, Sector 19',
  'Sanskriti Apartment Pocket B, Sector 19',
  'DDA Sfs Flats Cat- II & III Pocket 1, Sector 22',
  'MIG & Expandable Flats  Pocket 1, Sector 23',
  'DDA LIG Flats, Sector 26',



  ];


export const projectName = values => {
  return projectNameO.map(value => ({ key: value, text: value, value: value }));
};
