import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { searchUsers } from '../../actions/authAction';
import { closeModal } from '../../actions/modalAction';
import { withRouter } from 'react-router-dom';

import {
  Segment,
  Form, 
  Button,
  Header
} from 'semantic-ui-react';
import TextInput from '../form/TextInput';
//import TextArea from '../form/TextArea';
import SelectInput from '../form/SelectInput';
//import DateInput from '../form/DateInput';
import { userStatus,userTypeWOption } from "./Data";



const UserSearch = props => {
  const { handleSubmit, pristine, reset, submitting,  } = props;

  const onSubmit = values => {
   
    props.searchUsers(values, props.history);
    //this.props.closeModal();
  };
  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>
      <Segment
          textAlign="center"
          attached
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header
               // color="blue"
               content="Search Users"
              />
        </Segment>
        <div className="card-form ">
          <div className=" label" >
          <Form style={{ padding: 10 }} onSubmit={handleSubmit(onSubmit)}>
          <Button
              color="teal"
              size="mini"
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          <Button
            size="mini"
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
          <br /><br />
          <label htmlFor=""> Name of User</label>
              <Field
                name="uname"
                type="text"
                component={TextInput}
                placeholder="User Name"
              />
                <label htmlFor="">Phone</label>
                <Field
                  name="uphone"
                  type="text"
                  component={TextInput}
                  placeholder="Phone1"
                />
                
                
              <label htmlFor="">User Email</label>
              <Field
                name="uemail"
                type="email"
                component={TextInput}
                placeholder="User Email"
              />
              <label htmlFor="">User Type</label>
              <Field
              //search={true}
              name="userTypeW"
              type="text"
              component={SelectInput}
              options={userTypeWOption()}
              placeholder="User Type"
            />
            <label htmlFor="">User Status</label>
              <Field
              //search={true}
              name="userStatus"
              type="text"
              component={SelectInput}
              options={userStatus()}
              placeholder="User Status"
            />

              <br />
            <Button
              //onClick={this.props.onClose}
              color="olive"
              type="submit"
             // disabled={pristine || submitting}
            >
              Submit
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
          </Form>
          <br/><br/>
            </div>
            </div>
            </div>
  );
};

export default connect(
  null,
  { searchUsers, closeModal }
)(
  reduxForm({
    form: 'UserSearch', // a unique identifier for this form
    destroyOnUnmount: false

    //validate
  })(withRouter(UserSearch))
);
