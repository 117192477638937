import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import {fetchUser} from "../../actions/authAction";
import {
  
  Container,
 // Divider,
  Segment,
  Button,
  Header,
 // Confirm
} from "semantic-ui-react"; 




class UserDetail extends Component {
  state = {
    id: "",
    open: false,
    result: "show the modal to capture a result"
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchUser(id);
  }

  // show = id => {
  //   console.log("accTYPidShow:", id);
  //   this.setState({ open: true, id: id });
  // };
  // handleConfirm = () => {
  //   const did = this.state.id;
  //   const values = this.state;
  //   this.setState({ result: "confirmed", open: false });
  //   console.log("accTYPidHC:", did);
  //   this.props.deleteUser(did, this.props.history);
  //   this.props.fetchUsers(values);
  // };
  // handleCancel = () => this.setState({ result: "cancelled", open: false });

  render() {
    const {
      userId, uphone1, uphone2, uwhatsapp,
      uname,
      uemail,
      companyName,
     // city,
      _id,
      userTypeW,
      resetPasswordToken,
      resetPasswordExpires,
      userStatus,
      userSubscriptionStartOn,
      userSubscriptionExpireOn,
      
    } =
      this.props.user !== undefined &&
      this.props.user !== null &&
      this.props.user;
   // const { open, result } = this.state;
    
    return (
      <Container text>
        
        <Segment
          textAlign="center"
          attached="top"
          inverted
          color="teal"
          style={{ border: "none" }}
        >
          <Header> User Detail</Header>
        </Segment>
        <Segment attached>
          <Button.Group size="mini" floated="right">
            <Button
              // basic
              inverted
              floated="right"
              size="mini"
              color="green"
              type="button"
              as={Link}
              to={`/company/UserEdit/${_id}`}
            >
              Edit
            </Button>
            {/*<Button
              // basic
              inverted
              floated="right"
              size="mini"
              color="red"
              type="button"
              onClick={() => this.show()}
            >
              Delete
            </Button>*/}
          </Button.Group>
          <br />
          <br />
          <div >
        {userId && (
        <div className='row'>
        <div className='side'>
        <b>User Id</b>
        </div>
        <div className='main'>
        <b>{userId}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uphone1 && (
        <div className='row'>
        <div className='side'>
        <b>User Phone1</b>
        </div>
        <div className='main'>
        <b>{uphone1}</b>
        </div>
       
        </div>
        )}
        </div>
        <div >
        {uphone2 && (
        <div className='row'>
        <div className='side'>
        <b>User Phone2</b>
        </div>
        <div className='main'>
        <b>{uphone2}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uwhatsapp && (
        <div className='row'>
        <div className='side'>
        <b>Whatsapp Id</b>
        </div>
        <div className='main'>
        <b>{uwhatsapp}</b>
        </div>

        </div>
        )}
        </div>

          <div >
        {uname && (
        <div className='row'>
        <div className='side'>
        <b>User Name</b>
        </div>
        <div className='main'>
        <b>{uname}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uemail && (
        <div className='row'>
        <div className='side'>
        <b>User Email</b>
        </div>
        <div className='main'>
        <b>{uemail}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {companyName && (
        <div className='row'>
        <div className='side'>
        <b>Company Name</b>
        </div>
        <div className='main'>
        <b>{companyName}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uwhatsapp && (
        <div className='row'>
        <div className='side'>
        <b>Wwhatsapp Id</b>
        </div>
        <div className='main'>
        <b>{uwhatsapp}</b>
        </div>

        </div>
        )}
        </div>

        <div >
        {userTypeW && (
        <div className='row'>
        <div className='side'>
        <b>User Type</b>
        </div>
        <div className='main'>
        <b>{userTypeW}</b>
        </div>

        </div>
        )}
        </div>
        <div >
              {userStatus && (
              <div className='row'>
              <div className='side'>
              <b>userStatus</b>
              </div>
              <div className='main'>
              <b>{userStatus}</b>
              </div>

              </div>
              )}
              </div>

        <div >
              {resetPasswordToken && (
              <div className='row'>
              <div className='side'>
              <b>resetPasswordToken</b>
              </div>
              <div className='main'>
              <b>{resetPasswordToken}</b>
              </div>

              </div>
              )}
              </div>
              <div >
              {resetPasswordExpires && (
              <div className='row'>
              <div className='side'>
              <b>resetPasswordExpires</b>
              </div>
              <div className='main'>
              <b>{resetPasswordExpires}</b>
              </div>

              </div>
              )}
              </div>
              <div > 
                  {userSubscriptionStartOn && (
                  <div className='row'>
                  <div className='side'>
                  <b>Subscription Start On</b>
                  </div>
                  <div className='main'>

                  <b>{format(new Date(userSubscriptionStartOn), "MMM d yyyy")}</b>{' '}
                  
                  </div>

                  </div>
                  )}
                  </div>

                  <div >
                  {userSubscriptionExpireOn && (
                  <div className='row'>
                  <div className='side'>
                  <b>Subscription Expire On</b>
                  </div>
                  <div className='main'>

                  <b>{format(new Date(userSubscriptionExpireOn), "MMM d yyyy")}</b>{' '}
                  
                  </div>

                  </div>
                  )}
                  </div>
          
                  <div >
              {_id && (
              <div className='row'>
              <div className='side'>
              <b>_id</b>
              </div>
              <div className='main'>
              <b>{_id}</b>
              </div>

              </div>
              )}
              </div>
        </Segment>
      </Container>
    );
  
  
  }
}


const mapStateToProps = ({ auth }) => {

  return {
    user: auth.userD,
    userA:auth.userA
  };
};

export default connect(
  mapStateToProps,
  {fetchUser}
)(UserDetail);
