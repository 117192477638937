import axios from "axios";
import { toastr } from "react-redux-toastr";

import {openModal, closeModal } from "./modalAction";

import {
  FETCH_USER,
  FETCH_REQCOMS,
  FETCH_REQCOM,
  SEARCH_REQCOMS,
  FETCHING_REQCOMS,
} from "./types";


//search  ReqComs

export const searchReqComs = (
  values,
  limit,
  offset
) => async dispatch => {

  try {
    const res = await axios.post("/api/ReqComSearch", {
      values,
      limit,
      offset
    });

    dispatch({ type: SEARCH_REQCOMS, payload: res.data });
    dispatch({ type:FETCHING_REQCOMS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
};


//****************************
export const searchReqComsUnlocked = (
  id,
  values,
  limit,
  offset
) => async dispatch => {
  try {
    const res = await axios.post(`/api/ReqComSearchUnlocked/${id}`, {
      values,
      limit,
      offset
    });

    dispatch({ type: SEARCH_REQCOMS, payload: res.data });
    dispatch({ type:FETCHING_REQCOMS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
};

//fetch individual  MyReqCom
export const fetchMyReqComs = () => async dispatch => {
  try {
    const res = await axios.post(`/api/MyReqComs`);

    dispatch({ type: FETCH_REQCOMS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//fetch single ReqCom
export const fetchReqCom = id => async dispatch => {
  try {
    const res = await axios.get(`/api/ReqComDetail/${id}`);

    dispatch({ type: FETCH_REQCOM, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
};

//delete ReqCom
export const deleteReqCom = (id) => async dispatch => {
  try {
    const res = await axios.delete(`/api/ReqComDelete/${id}`);
    if(res.data.msg ==='success'){
      toastr.success('Requirement Deleted')
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
}; 
//update ReqCom
export const updateReqCom = (id, values) => async dispatch => {
  try {
    const res = await axios.put(`/api/ReqComEdit/${id}`, values);

    if(res.data.msg ==='success'){
      toastr.success('Requirement Updated')
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
};
//#####################################################
//update PropCom to INACTIVE
export const updateReqComToInactive = (id) => async dispatch => {
  try {
    const res = await axios.put(`/api/ReqComInactive/${id}`);

  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
};



// CREATE REQCOMMENT
export const createReqCom = (values) => async dispatch => {
  try {
  
    const res = await axios.post(`/api/ReqComCreate`, {
      values
    });

    if (res.data.msg ==='success') {
      toastr.success('ReqCom Created Successfully');
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
};
