import React, { Component } from 'react';
import {  withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import {fetchCompany,updateCompany} from '../../actions/companysAction';

import { Segment, Form, Button,  Header } from 'semantic-ui-react';
import TextInput from '../form/TextInput';
import TextArea from '../form/TextArea';
import SelectInput from '../form/SelectInput';
import DateInput from '../form/DateInput';

import {  typeOfProp, typeOfDealings,locality,companyStatus, } from './Data';

class CompanyUpdate extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchCompany(id); 
  }

  onSubmit = values => {
    const { id } = this.props.match.params;
    this.props.updateCompany(id, values, this.props.history);
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    return (
      <div style={{maxWidth:'700px',margin:'auto'}}>

            <br/>
        <Segment
          textAlign="center"
          //attached="bottom"
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header
               // color="blue"
               content="Edit Company"
              />
        </Segment>
        <div className="card-form ">
          <div className=" label" >

            <Form
              style={{ padding: 25 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >

            <Button
            color="teal"
            size="mini"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
            <Button
              size="mini"
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <br /><br />

              <label >Company's Phone1</label>
              <Field
                name="cmobile1"
                type="text"
                component={TextInput}
                placeholder="Company's Phone1"
              />
              <label >Company's Phone2</label>
              <Field
                name="cmobile2"
                type="text"
                component={TextInput}
                placeholder="Company's Phone2"
              />
              <label >Company's Email</label>
              <Field
                name="cemail"
                type="text"
                component={TextInput}
                placeholder="Company's Email"
              />
              <label >Company's Website</label>
              <Field
                name="website"
                type="text"
                component={TextInput}
                placeholder="Company's Website"
              />
              <label>Company's RERA No.</label>
            <Field
              name="reraNo"
              type="text"
              component={TextInput}
              placeholder="Enter RERA Number"
            />
              <label >Operating Since</label>
              <Field
                name="operatingSince"
                type="text"
                component={DateInput}
                dateFormat="dd-MM-yyyy "
                placeholder="Operating Since"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <label >Type of Dealings</label>
              <Field
                //search={true}
                multiple={true}
                name="typeOfDealings"
                type="text"
                component={SelectInput}
                options={typeOfDealings()}
                placeholder="Type of Dealings"
              />
              <label >Types of properties deals in</label>
              <Field
                //search={true}
                multiple={true}
                name="typeOfPropertyDealsIn"
                type="text"
                component={SelectInput}
                options={typeOfProp()}
                placeholder="Types of properties deals in"
              />
              <label>Address</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="address"
              type="text"
              component={TextInput}
              placeholder="Address"
            />
              <label >Locality</label>
              <Field
                name="locality"
                placeholder="Locality"
                type="text"
                options={locality()}
                //search={true}
                component={SelectInput}
              />
              {/* <label >City/District</label>
            <Field
              //search={true}
              name="city"
              type="text"
              component={SelectInput}
              options={city()}
              placeholder="City/District"
            />
            <label >State</label>
            <Field
              //search={true}
              name="state"
              type="text"
              component={SelectInput}
              options={state}
              placeholder="State"
            /> */}
              <label >Pincode</label>
              <Field
                name="pincode"
                type="text"
                component={TextInput}
                placeholder="Pincode"
              />
              <label >Location On Map</label>
            <Field
              name="locationOnMap"
              type="text"
              component={TextInput}
              placeholder="Location On Map"
            />
              <label >Tell us about your Company</label>
              <Field
                name="description"
                type="text"
                component={TextArea}
                rows={3}
                placeholder="Tell us about your Company"
              />
              <label >Description For Advertisement</label>
              <Field
                name="noteForAd"
                type="text"
                component={TextArea}
                rows={3}
                placeholder="Description For Advertisement"
              />
              <Header sub color="teal" content="Contact Persons Details" />
              <label >First Contact person's Name</label>
              <Field
                name="nameCP1"
                type="text"
                component={TextInput}
                placeholder="First Contact person's Name"
              />
              <label >First Contact person's Mobile</label>
              <Field
                name="mobileCP1"
                type="text"
                component={TextInput}
                placeholder="First Contact person's Mobile"
              />
              <label >First Contact person's Email</label>
              <Field
                name="emailCP1"
                type="text"
                component={TextInput}
                placeholder="First Contact person's Email"
              />
              <label >First Contact person's Designation</label>
              <Field
                name="designationCP1"
                type="text"
                component={TextInput}
                placeholder="First Contact person's Designation"
              />
              <label >Second Contact person's Name</label>
              <Field
                name="nameCP2"
                type="text"
                component={TextInput}
                placeholder="Second Contact person's Name"
              />
              <label >Second Contact person's Mobile</label>
              <Field
                name="mobileCP2"
                type="text"
                component={TextInput}
                placeholder="Second Contact person's Mobile"
              />
              <label >Second Contact person's Email</label>
              <Field
                name="emailCP2"
                type="text"
                component={TextInput}
                placeholder="Second Contact person's Email"
              />
              <label >Second Contact person's Designation</label>
              <Field
                name="designationCP2"
                type="text"
                component={TextInput}
                placeholder="Second Contact person's Designation"
              />
              {/*  */}
            <label >Company Subscription Start On</label>
              <Field
                name="companySubscriptionStartOn"
                type="text"
                component={DateInput}
                placeholder="Started On"
                dateFormat="dd-MM-yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <label >Company Subscription Expire On</label>
              <Field
                name="companySubscriptionExpireOn"
                type="text"
                component={DateInput}
                placeholder="Expired On"
                dateFormat="dd-MM-yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />

                <label >companyStatus</label>
                <Field
                //search={true}
                name="companyStatus"
                type="text"
                component={SelectInput}
                options={companyStatus()}
                placeholder="companyStatus"
                />
                {/*  */}
              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            </Form>

                          <br/>
                        </div>
                        </div>
                        </div>
    );
  }
}

export default connect(
  state => {
  
    return { initialValues: state.companys.company }; // pull initial values from account reducer
  },
  {fetchCompany,updateCompany}
)(
  reduxForm({
    form: 'EditCompanyForm' // a unique identifier for this form
    //validate
  })(withRouter(CompanyUpdate))
);
