import {
  CREATE_DEALERSUGGESTEDS,
  FETCH_DEALERSUGGESTEDS,
  FETCH_DEALERSUGGESTED,
  //DELETE_DEALERSUGGESTED,
  UPDATE_DEALERSUGGESTED,
  SEARCH_DEALERSUGGESTEDS,
  // DESELECT_DEALERSUGGESTED,
  // SELECT_DEALERSUGGESTED,
  FETCHING_DEALERSUGGESTEDS,
} from '../actions/types';

const INITIAL_STATE = {
  fetching:true,
  dealerSuggestedsForAdSideColumn: [],
  dealerSuggestedsForProp: [],
  dealerSuggestedsForProj: [],
    adByDealer: [],
  dealerSuggested: null,
  offsetDS: 0,
  limitDS: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DEALERSUGGESTEDS:
      return { ...state, ...action.payload };
    case FETCH_DEALERSUGGESTED:
      return { ...state, dealerSuggested: action.payload };
    case CREATE_DEALERSUGGESTEDS:
      return {
        ...state,
        dealerSuggesteds: [...state.dealerSuggesteds, ...action.payload]
      };
    case UPDATE_DEALERSUGGESTED:
      return {
        ...state,
        dealerSuggested: { ...state.dealerSuggested, ...action.payload }
      };
    case SEARCH_DEALERSUGGESTEDS:
      
      return { ...state, ...action.payload };
      case FETCHING_DEALERSUGGESTEDS:
        return { ...state, fetching: action.payload };

    default:
      return state;
  }
};
