const sortingO = [
  'Demand-Low To High',
  'Demand-High To Low',
  'Newest',
  'Oldest'
];
export const sorting = () => {
  return sortingO.map(value => ({ key: value, text: value, value: value }));
};


//#########
const categoryO = [ 'MALL','DDA MKT COMPLEX', 'SHOPPING cum OFFICE COMPLEX','DDA-FLAT','BUILDER-FLOOR'];
export const category = () => {
  return categoryO.map(value => ({ key: value, text: value, value: value }));
};

//###############
const projectTypeO = [
  // 'Residential',
  'Commercial'
];
export const projectType = () => {
  return projectTypeO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const propertyTypeProjO = [
  'Office Space',
    'Shop',
    'Commercial Space',
    'Showroom'
];
export const propertyTypeProj = () => {
  return propertyTypeProjO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

const configurationO = ['2BHK','2BHK +Store','2BHK +Study', '3BHK','3BHK +Servant','3BHK +Store','3BHK +Study',  '4BHK','4BHK +Servant','4BHK +Store','4BHK +Study', '5BHK','5BHK +Servant','5BHK +Store','5BHK +Study'];
export const configuration = () => {
  return configurationO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

//#######
const amenitiesBasicO = [

'Power Back Up'	,
 '24 Hour Water Supply'	,
'Car Parking'	,
'Fire Fighting Systems',
'Lift(s)',
'RO Water System',
'Water Storage',
'Visitor Parking',
'Service/Goods Lift',
'Escalators',
'Garbage Disposal',

  'Maintenance Staff'	,
  'Internet/wi-fi connectivity',
  'Centrally Air Conditioned',
'CCTV Camera Security',
'24x7 Security',
'Security Cabin',
'Electronic Security',
'Security / Fire Alarm'	,
'Security Personnel',
'Fire Fighting Equipment',
'Bar/Chill-Out Lounge',
'Shopping Center'	,
'Restaurant',
'Food Court',
'Cafeteria',
'Barbecue',
'Party Lawn',
'Reading Lounge',
'Theatre',
'Banquet Hall',
'Waiting Lounge',
'Conference Room',
'Grocery Shop',
'Bank & ATM'
];
export const amenitiesBasic = () => {
  return amenitiesBasicO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};



//############
const ageOfConstructionO = ['0+','01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '10+','15+','20+'];
export const ageOfConstruction = () => {
  return ageOfConstructionO.sort().map(value => ({ key: value, text: value, value: value }));
};

//#######
const transactionTypeO = ['Resale', 'New Property', 'New Booking'];
export const transactionType = () => {
  return transactionTypeO.map(value => ({ key: value, text: value, value: value }));
};


//#######
const statusO = ['New launch','Under Construction','Ready To Move','Upcoming']; //Immediate Possession /possession by date

export const status = () => {
  return statusO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//#######
const possessionO = ['','Ready To Move', 'Ongoing']; //Immediate Possession

export const possession = () => {
  return possessionO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//############
const totalFloorsO = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '10+'
];
export const totalFloors = () => {
  return totalFloorsO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############

//###############
const localityO = [
  'Sector-01',
  'Sector-02',
  'Sector-03',
  'Sector-04',
  'Sector-05',
  'Sector-06',
  'Sector-07',
  'Sector-08',
  'Sector-09',
  'Sector-10',
  'Sector-11',
  'Sector-12',
  'Sector-13',
  'Sector-14',
  'Sector-15',
  'Sector-16',
  'Sector-17',
  'Sector-18A',
  'Sector-18B',
  'Sector-19A',
  'Sector-19B',
  'Sector-20',
  'Sector-21',
  'Sector-22',
  'Sector-23',
  'Sector-24',
  'Sector-25',
  'Sector-26',
  'Sector-27',
  'Sector-28',
  'Sector-29',
  'Sector-30'
];
export const locality = () => {
  return localityO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const cityO = ['Dwarka', 'Gurugram','Noida', 'Rohini'];
export const city = () => {
  return cityO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const stateO = ['Delhi', 'Haryana', 'U P'];
export const state = () => {
  return stateO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//**************
const projectStatusO = ['active', 'inactive'];
export const projectStatus = () => {
  return projectStatusO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//**************
const adAllowedToDealersO = ['Some', 'All'];
export const adAllowedToDealers = () => {
  return adAllowedToDealersO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};