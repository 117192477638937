		 import React, { Component } from "react";
		import { connect } from "react-redux";
		import format from "date-fns/format";
		import { Link, withRouter } from "react-router-dom";
		import {fetchPropResi,deletePropResi} from "../../actions/propresiAction";
		import { openModal } from "../../actions/modalAction";
		import ContactDetail from './ContactDetail';
		import writtenNumber from "written-number";
		
		import {
		  Button,
		  Confirm,
		  Icon,
		} from "semantic-ui-react";

		

		class PropResiDetail extends Component {
		  state = {
			showVideo: {},
			modalOpenImg: false,
			open: false,
			result: "show the modal to capture a result"
		  };
		  componentDidMount() {
			const { id } = this.props.match.params;
			this.props.fetchPropResi(id);
		  }
		  componentDidUpdate(prevProps) {
			//Typical usage, don't forget to compare the props
			if (this.props.match.params.id !== prevProps.match.params.id) {
				const { id } = this.props.match.params;
				this.props.fetchPropResi(id);
				window.scroll(0,0)
			}
		   }
		  show = () => {
			
			this.setState({ open: true });
		  };
		  handleConfirm = () => {
			
			this.setState({ result: "confirmed", open: false });
			
			const { id } = this.props.match.params;

			this.props.deletePropResi(id, this.props.history);
		  };
		  handleCancel = () => this.setState({ result: "cancelled", open: false });
		  
		  //***************************BACK BUTTON */



		   capitalizeFirst=(input)=> {
			var words = input.split(' ');
			var CapitalizedWords = [];
			words.forEach(element => {
				CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
			});
			return CapitalizedWords.join(' ');
		}



//**************** */
handleOpenModalImage = (id,imageOf) => {   
	this.props.openModal("ShowImagesForEditModal" , {id:id,imageOf:imageOf});
  };
  //^^^^^^^^^^^^^^^^^^
  handleOpenModalVideo = (id,imageOf) => {   
	this.props.openModal("ShowVideoForEditModal" , {id:id,imageOf:imageOf});
  };
  //****** */


		  //********************************* */
		  render() {
			const { open,  } = this.state;
			if (!this.props.propresi) {
			  return (
				<div><span style={{marginLeft: '200px',fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

				</div>
			  );
			}
			const {
				imagePath,
				name,
				phone1,
				phone2,
				email,
				whatsapp,
				propertyFor,
				propertyType,
				bedroom,
				bathroom,
				balcony,
				additionalRooms,
				furnishedStatus,
				aproxValueOfWork,
				expectedPrice,
				facing,
				entry,
				Overlooking,
				carParkingCovered,
				carParkingOpen,
				floorNo,
				totalFloors,
				projectType,
				projectName,
				projectId,
				address,
				locality,
				city,
				locationOnMap,
				landmarks,
				description,
				createdAt,
				//furnishing details
				interior,
				wardrobes,
				ac,
				fan,
				bed,
				diningTable,
				sofa,
				curtains,
				tv,
				geyser,
				bathtub,
				modularKitchen,
				refrigerator,
				chimney,
				iglGasConnection,
				hob,
				microwave,
				ro,
				washingMachine,
				others,
				expectedRent,
				securityDeposit,
				maintenanceCharges,
				unitMaintenanceCharges,
				otherCharges,
				unitOtherCharges,
				//availableFrom,
				willingToRentOutTo,
				notWillingToRentOutTo,
				//tenantsProfession,
				tenantsWhoAreNonVegetarians,
				tenantsWithPets,
				companyLease,
				agreement,
				//
				brokerResponse,
				typeOfOwnership,
				loanOfferedBy,
				status,
				possession,
				category,
				area,
				areaUnit,
				advertisedBy,
				liftsInTheTower,
				//@@@@@
				startedOn,
				expiredOn,
				financerId,
				financerName,
				creditRequireForAd,
				creditRequireForFinancerAd,
				creditRemainToLock,
				creditRequireToLock,
				adAllowedToDealers,
				idsOfDealerAllowedToAd,
				userS,
				userName,
				propresiStatus,
				//@@@
				_id

			} = this.props.propresi !== null && this.props.propresi;

			const contact={name,
								phone1,
								phone2,
								email,
								whatsapp,
							  _id
								 }

			 
//***************************
// const values= {
// 	bedroom,projectId,
// }
//**********************

const renderidsOfDealerAllowedToAd = () => {
	return idsOfDealerAllowedToAd.map(item => {
	  return (
		<span key={item}>
		  {" "}
		  <b>{item} |</b>
		</span>
	  );
	});
  };

		  
			const renderadditionalRooms = () => {
			  return additionalRooms.map(item => {
				return (
				  <span key={item}>
					{" "}
					<b>{item} |</b>
				  </span>
				);
			  });
			};
			const renderButton = () =>
			  this.props.userA !== null &&
			  (
				this.props.userA.userType === "admin") ? (
				<Button
				  // basic

				  inverted
				  floated="right"
				  size="mini"
				  color="red"
				  type="button"
				  onClick={() => this.show(_id)}
				>
				  Delete
				</Button>
			  ) : (
				<div></div>
			  );

			  //******************
		const renderAddImageButton = () =>
		this.props.userA !== null &&
		(this.props.userA.userType === "admin") ? (
		  <Button
			// basic
			inverted
			floated="right"
			size="mini"
			color="orange"
			type="button"
			as={Link}
			to={`/activity/AddImageVideo/${_id}`}
		  >
			Add Image
		  </Button>
		) : (
		  <div></div>
		);
  //^^^^^^^^^^^^^^^
	  
	  
			return (
			  <div>
			  <div className='maindiv-820'>

			  <div>




			  <div style={{boderRadius:'2px',marginBottom:'0px'}} >
			  <img
			  className= 'yimg imgDimention-detailPage'
			    src={`${imagePath}`}
			//  src={require('../landing/img/showcase.jpg')}
			  alt="prop_image" 
			//   width="100%" height="300"
			  
			  />
		</div>
		
			  <Confirm
				// header={_id}
				size="mini"
				open={open}
				onCancel={this.handleCancel}
				onConfirm={() => this.handleConfirm()}
			  />

<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > {bedroom} BHK Residential Property For {propertyFor} in {projectName}</span>
	</div>

	</div>

	<div className='card-attached border-sb pxy-20'  >
{/*  */}
<span
 onClick={()=>this.handleOpenModalImage(_id,'PropertyR')}
 className="icon-button"
 >
 <img className= 'yimg ' src='/imageAddIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 {/*  */}
 <span
 onClick={()=>this.handleOpenModalVideo(_id,'PropertyR')} 
 className="icon-button"
 >
 <img className= 'yimg ' src='/videoViewIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 {/*  */}
				<div className='display-groupButton'> 
				
				
				{renderAddImageButton()}
				<Button
					// basic
					inverted
					floated="right"
					size="mini"
					color="green"
					type="button"
					as={Link}
					to={`/propresi/PropResiEdit/${_id}`}
				  >
					Edit
				  </Button>
				
				  
				  {renderButton()}
				</div>
				<br />
				<br />
		<div>
		</div>


		<div><h3 className='border-bottom'>Property Detail</h3></div>
		<div >
	{propertyFor && (
	<div className='row'>
	<div className='side'>
	<b>Property For</b>
	</div>
	<div className='main'>
	<b>{propertyFor}</b>
	</div>

	</div>
	)}
	</div>
				<div >
				{expectedPrice > 0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Price</b>
					</div>
					<div className='main'>
					<Icon className="rupee" ></Icon>
					  <b> {expectedPrice}</b>
					  <b>
					   {' '} ({this.capitalizeFirst(writtenNumber(expectedPrice, {
						  lang: "enIndian"
						}))}
						){" "}
					  </b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{expectedRent > 0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Rent</b>
					</div>
					<div className='main'>
					<Icon className="rupee" ></Icon>
					  <b> {expectedRent}</b>
					  <b>
					  {' '} ({this.capitalizeFirst(writtenNumber(expectedRent, {
						  lang: "enIndian"
						}))}
						){" "}

					  </b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{securityDeposit > 0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Security Deposit</b>
					</div>
					<div className='main'>
					<Icon className="rupee" ></Icon>
					  <b> {securityDeposit}</b>
					  <b>
					  {' '} ({this.capitalizeFirst(writtenNumber(securityDeposit, {
						  lang: "enIndian"
						}))}
						){" "}

					  </b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{maintenanceCharges > 0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Maintenance Charges</b>
					</div>
					<div className='main'>
					<Icon className="rupee" ></Icon>
					  <b> {maintenanceCharges}</b>
					  <b>
					  {' '} ({this.capitalizeFirst(writtenNumber(maintenanceCharges, {
						  lang: "enIndian"
						}))}
						){" "}

					  </b>
					  {unitMaintenanceCharges && (


							<b>{unitMaintenanceCharges}</b>

					  )}
					</div>

				  </div>
				)}
				</div>

				<div >
				{otherCharges > 0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Other Charges</b>
					</div>
					<div className='main'>
					<Icon className="rupee" ></Icon>
					  <b> {otherCharges}</b>
					  <b>
					  {' '} ({this.capitalizeFirst(writtenNumber(otherCharges, {
						  lang: "enIndian"
						}))}
						){" "}

					  </b>
					  {unitOtherCharges && (


							<b>{unitOtherCharges}</b>

					  )}
					</div>

				  </div>
				)}
				</div>

				<div >
				{propertyType && (
				  <div className='row'>
					<div className='side'>
					  <b>Type Of Property</b>
					</div>
					<div className='main'>
					  <b>{propertyType}</b>
					</div>

				  </div>
				)}
			  </div>
			  <div >
				{category && (
				  <div className='row'>
					<div className='side'>
					  <b>Category</b>
					</div>
					<div className='main'>
					  <b>{category}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{status && (
				  <div className='row'>
					<div className='side'>
					  <b>Status</b>
					</div>
					<div className='main'>
					  <b>{status}</b>
					</div>

				  </div>
				)}
				</div>

				<div >
				{possession && (
				  <div className='row'>
					<div className='side'>
					  <b>Possession</b>
					</div>
					<div className='main'>

					  <b>{ format(new Date(possession), "dd-MM-yyyy")}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{area && (
				  <div className='row'>
					<div className='side'>
					  <b>Area</b>
					</div>
					<div className='main'>
					  <b>{area}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{areaUnit && (
				  <div className='row'>
					<div className='side'>
					  <b>Unit Of Area</b>
					</div>
					<div className='main'>
					  <b>{areaUnit}</b>
					</div>

				  </div>
				)}
				</div>

				<div >
				{bedroom && (
				  <div className='row'>
					<div className='side'>
					  <b>Bedrooms</b>
					</div>
					<div className='main'>
					  <b>{bedroom}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{bathroom && (
				  <div className='row'>
					<div className='side'>
					  <b>Bathrooms</b>
					</div>
					<div className='main'>
					  <b>{bathroom}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{balcony && (
				  <div className='row'>
					<div className='side'>
					  <b>Balconys</b>
					</div>
					<div className='main'>
					  <b>{balcony}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{additionalRooms &&
				  additionalRooms.length > 0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Additional Rooms</b>
					</div>
					<div className='main'>
					  {renderadditionalRooms()}
					</div>

				  </div>
				)}
				</div>

				{/* furnishing details  START*/}
				<div >
				{furnishedStatus && (
				  <div className='row'>
					<div className='side'>
					  <b>Furnished Status</b>
					</div>
					<div className='main'>
					  <b>{furnishedStatus}</b>
					</div>

				  </div>
				)}
				</div>

				<div >
				{aproxValueOfWork > 0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Value Of Work</b>
					</div>
					<div className='main'>
					<Icon className="rupee" ></Icon>
					  <b>{aproxValueOfWork}</b>
					  <b>
					  {' '} ({this.capitalizeFirst(writtenNumber(aproxValueOfWork, {
						  lang: "enIndian"
						}))}
						){" "}

					  </b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{interior && (
				  <div className='row'>
					<div className='side'>
					  <b>Interior</b>
					</div>
					<div className='main'>
					  <b>{interior}</b>
					</div>

				  </div>
				)}
			  </div>
			  <div >
			  {wardrobes && (
				<div className='row'>
				  <div className='side'>
					<b>Wardrobes</b>
				  </div>
				  <div className='main'>
					<b>{wardrobes}</b>
				  </div>

				</div>
			  )}
			</div>
			<div >
			{ac && (
			  <div className='row'>
				<div className='side'>
				  <b>AC</b>
				</div>
				<div className='main'>
				  <b>{ac}</b>
				</div>

			  </div>
			)}
		  </div>
		  <div >
		  {fan && (
			<div className='row'>
			  <div className='side'>
				<b>Fan</b>
			  </div>
			  <div className='main'>
				<b>{fan}</b>
			  </div>

			</div>
		  )}
		</div>
		<div >
		{bed && (
		  <div className='row'>
			<div className='side'>
			  <b>Bed</b>
			</div>
			<div className='main'>
			  <b>{bed}</b>
			</div>

		  </div>
		)}
		</div>

		<div >
		{diningTable && (
		<div className='row'>
		  <div className='side'>
			<b>Dining Table</b>
		  </div>
		  <div className='main'>
			<b>{diningTable}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{sofa && (
		<div className='row'>
		  <div className='side'>
			<b>Sofa</b>
		  </div>
		  <div className='main'>
			<b>{sofa}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{curtains && (
		<div className='row'>
		  <div className='side'>
			<b>Curtains</b>
		  </div>
		  <div className='main'>
			<b>{curtains}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{tv && (
		<div className='row'>
		  <div className='side'>
			<b>TV</b>
		  </div>
		  <div className='main'>
			<b>{tv}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{geyser && (
		<div className='row'>
		  <div className='side'>
			<b>Geyser</b>
		  </div>
		  <div className='main'>
			<b>{geyser}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{bathtub && (
		<div className='row'>
		  <div className='side'>
			<b>Bathtub</b>
		  </div>
		  <div className='main'>
			<b>{bathtub}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{modularKitchen && (
		<div className='row'>
		  <div className='side'>
			<b>Modular Kitchen</b>
		  </div>
		  <div className='main'>
			<b>{modularKitchen}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{chimney && (
		<div className='row'>
		  <div className='side'>
			<b>Chimney</b>
		  </div>
		  <div className='main'>
			<b>{chimney}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{iglGasConnection && (
		<div className='row'>
		  <div className='side'>
			<b>Piped Gas</b>
		  </div>
		  <div className='main'>
			<b>{iglGasConnection}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{hob && (
		<div className='row'>
		  <div className='side'>
			<b>Hob</b>
		  </div>
		  <div className='main'>
			<b>{hob}</b>
		  </div>

		</div>
		)}
		</div>

		<div >
		{microwave && (
		<div className='row'>
		  <div className='side'>
			<b>Microwave</b>
		  </div>
		  <div className='main'>
			<b>{microwave}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{ro && (
		<div className='row'>
		  <div className='side'>
			<b>Water Purifier</b>
		  </div>
		  <div className='main'>
			<b>{ro}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{washingMachine && (
		<div className='row'>
		  <div className='side'>
			<b>Washing Machine</b>
		  </div>
		  <div className='main'>
			<b>{washingMachine}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{refrigerator && (
		<div className='row'>
		  <div className='side'>
			<b>Refrigerator</b>
		  </div>
		  <div className='main'>
			<b>{refrigerator}</b>
		  </div>

		</div>
		)}
		</div>

		<div >
		{others && (
		<div className='row'>
		  <div className='side'>
			<b>Other Item</b>
		  </div>
		  <div className='main'>
			<b>{others}</b>
		  </div>

		</div>
		)}
		</div>

		{/* furnishing details  END*/}
		{/* other rent details  START*/}
		<div >
		{willingToRentOutTo && (
		<div className='row'>
		  <div className='side'>
			<b>Willing To RentOut To</b>
		  </div>
		  <div className='main'>
			<b>{willingToRentOutTo}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{notWillingToRentOutTo && (
		<div className='row'>
		  <div className='side'>
			<b>Not Willing To RentOut To</b>
		  </div>
		  <div className='main'>
			<b>{notWillingToRentOutTo}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{tenantsWhoAreNonVegetarians && (
		<div className='row'>
		  <div className='side'>
			<b>Tenants Who Are NonVegetarians</b>
		  </div>
		  <div className='main'>
			<b>{tenantsWhoAreNonVegetarians}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{tenantsWithPets && (
		<div className='row'>
		  <div className='side'>
			<b>Tenants With Pets</b>
		  </div>
		  <div className='main'>
			<b>{tenantsWithPets}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{companyLease && (
		<div className='row'>
		  <div className='side'>
			<b>Company Lease</b>
		  </div>
		  <div className='main'>
			<b>{companyLease}</b>
		  </div>

		</div>
		)}
		</div>
		<div >
		{agreement && (
		<div className='row'>
		  <div className='side'>
			<b>Agreement</b>
		  </div>
		  <div className='main'>
			<b>{agreement}</b>
		  </div>

		</div>
		)}
		</div>

		{/* other rent details  END*/}


				<div >
				{entry && (
				  <div className='row'>
					<div className='side'>
					  <b>Entry</b>
					</div>
					<div className='main'>
					  <b>{entry}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{facing && (
				  <div className='row'>
					<div className='side'>
					  <b>Facing</b>
					</div>
					<div className='main'>
					  <b>{facing}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{Overlooking && (
				  <div className='row'>
					<div className='side'>
					  <b>Overlooking</b>
					</div>
					<div className='main'>
					  <b>{Overlooking}</b>
					</div>

				  </div>
				)}
				</div>

				<div >
				{carParkingCovered && (
				  <div className='row'>
					<div className='side'>
					  <b>Covered Parking</b>
					</div>
					<div className='main'>
					  <b>{carParkingCovered}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{carParkingOpen && (
				  <div className='row'>
					<div className='side'>
					  <b>Open Parking</b>
					</div>
					<div className='main'>
					  <b>{carParkingOpen}</b>
					</div>

				  </div>
				)}
				</div>

				<div >
				{floorNo && (
				  <div className='row'>
					<div className='side'>
					  <b>Floor No.</b>
					</div>
					<div className='main'>
					  <b>{floorNo}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{totalFloors && (
				  <div className='row'>
					<div className='side'>
					  <b>Total Floors</b>
					</div>
					<div className='main'>
					  <b>{totalFloors}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{liftsInTheTower && (
				  <div className='row'>
					<div className='side'>
					  <b>Lifts In The Tower</b>
					</div>
					<div className='main'>
					  <b>{liftsInTheTower}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{address && (
				  <div className='row'>
					<div className='side'>
					  <b>Address</b>
					</div>
					<div className='main'>
					  <b>{address}</b>
					</div>

				  </div>
				)}
				</div>

				<div >
				{projectName && (
				  <div className='row'>
					<div className='side'>
					  <b>Society/Apartment</b>
					</div>
					<div className='main'>
					  <b>{projectName}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{projectId && (
				  <div className='row'>
					<div className='side'>
					  <b>Project Id</b>
					</div>
					<div className='main'>
					  <b>{projectId}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{projectType && (
				  <div className='row'>
					<div className='side'>
					  <b>ProjectType</b>
					</div>
					<div className='main'>
					  <b>{projectType}</b>
					</div>

				  </div>
				)}
				</div>

				<div >
				{locality && (
				  <div className='row'>
					<div className='side'>
					  <b>Locality</b>
					</div>
					<div className='main'>
					  <b>{locality}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
     {city && (
       <div className='row'>
         <div className='side'>
           <b>City/District</b>
         </div>
         <div className='main'>
           <b>{city}</b>
         </div>

       </div>
     )}
   </div>
				
				<div >
				{landmarks && (
				  <div className='row'>
					<div className='side'>
					  <b>Landmarks</b>
					</div>
					<div className='main'>
					  <b>{landmarks}</b>
					</div>

				  </div>
				)}
				</div>
				
				<div >
				{locationOnMap && (
				  <div className='row'>
					<div className='side'>
					  <b>Location On Map</b>
					</div>
					<div className='main'>
					  <b>{locationOnMap}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{typeOfOwnership && (
				  <div className='row'>
					<div className='side'>
					  <b>Type Of Ownership</b>
					</div>
					<div className='main'>
					  <b>{typeOfOwnership}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{loanOfferedBy && (
				  <div className='row'>
					<div className='side'>
					  <b>Loan Offered By</b>
					</div>
					<div className='main'>
					  <b>{loanOfferedBy}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{createdAt && (
				  <div className='row'>
					<div className='side'>
					  <b>Advertised On</b>
					</div>
					<div className='main'>

					  <b>{ format(new Date(createdAt), "dd-MM-yyyy")}</b>
					</div>

				  </div>
				)}
				</div>


				<div >
				{description && (
				  <div className='row'>
					<div className='side'>
					  <b>About/USP OF Property</b>
					</div>
					<div className='main'>
					  <b>{description}</b>
					</div>

				  </div>
				)}
				</div>
				{/*  */}
				<br/>
				<div><h3 className='border-bottom'>For Ad And Other Functionality</h3></div>
				
				<div >
				{advertisedBy && (
				  <div className='row'>
					<div className='side'>
					  <b>Advertised By</b>
					</div>
					<div className='main'>
					  <b>{advertisedBy}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{brokerResponse && (
				  <div className='row'>
					<div className='side'>
					  <b>Broker Response</b>
					</div>
					<div className='main'>
					  <b>{brokerResponse}</b>
					</div>

				  </div>
				)}
				</div>

				<div >
				{userS && (
				  <div className='row'>
					<div className='side'>
					  <b>User Id</b>
					</div>
					<div className='main'>
					  <b>{userS}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{userName && (
				  <div className='row'>
					<div className='side'>
					  <b>User's Name</b>
					</div>
					<div className='main'>
					  <b>{userName}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{financerId && (
				  <div className='row'>
					<div className='side'>
					  <b>Financer's ID</b>
					</div>
					<div className='main'>
					  <b>{financerId}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{financerName && (
				  <div className='row'>
					<div className='side'>
					  <b>Financer's Name</b>
					</div>
					<div className='main'>
					  <b>{financerName}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{startedOn && (
				  <div className='row'>
					<div className='side'>
					  <b>Finance Property Started On</b>
					</div>
					<div className='main'>
					<b>{ format(new Date(startedOn), "dd-MM-yyyy")}</b>
					</div>

				  </div>
				)}
				</div>
				<div >
				{expiredOn && (
				  <div className='row'>
					<div className='side'>
					  <b>Finance Property Expired On</b>
					</div>
					<div className='main'>
					<b>{ format(new Date(expiredOn), "dd-MM-yyyy")}</b>
					</div>

				  </div>
				)}
				</div>

				<div >
				{creditRequireForAd >0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Credit Require For Ad</b>
					</div>
					<div className='main'>
					  <b>{creditRequireForAd}</b>
					</div>

				  </div>
				)}
				</div>
				
				<div >
				{creditRequireForFinancerAd >0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Credit Require For Financer Ad</b>
					</div>
					<div className='main'>
					  <b>{creditRequireForFinancerAd}</b>
					</div>

				  </div>
				)}
				</div>
				
				<div >
				{creditRequireToLock >0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Credit Require To Lock</b>
					</div>
					<div className='main'>
					  <b>{creditRequireToLock}</b>
					</div>

				  </div>
				)}
				</div>
				
				<div >
				{creditRemainToLock >0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Credit Remain To Lock</b>
					</div>
					<div className='main'>
					  <b>{creditRemainToLock}</b>
					</div>

				  </div>
				)}
				</div>

	<div >
	{adAllowedToDealers  && (
	<div className='row'>
	<div className='side'>
	<b>ad Allowed To Dealers</b>
	</div>
	<div className='main'>
	<b>{adAllowedToDealers}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{idsOfDealerAllowedToAd &&
	idsOfDealerAllowedToAd.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>ids Of Dealers Allowed To Ad</b>
	</div>
	<div className='main'>
	{renderidsOfDealerAllowedToAd()}
	</div>

	</div>
	)}
	</div>

				
				<div >
				{propresiStatus && (
				  <div className='row'>
					<div className='side'>
					  <b>propresi Status</b>
					</div>
					<div className='main'>
					  <b>{propresiStatus}</b>
					</div>

				  </div>
				)}
				</div>


				{/*  */}
			  </div>
			  <ContactDetail contact={contact}  />
			  
        {/*  */}
			  <br/><br/><br/><br/>
			  </div>
			  


			  </div>




			  </div>

			  //**********************************



			);
		  }
		}
		function mapStateToProps(state) {
		 
		  return {
			propresi: state.propresis.propresi,
			userA: state.auth.userA,
		  };
		}

		export default connect(
		  mapStateToProps,
		  {fetchPropResi,deletePropResi,
			openModal}
		)(withRouter(PropResiDetail));
