import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

//import writtenNumber from 'written-number';
import {fetchBuilders} from "../../actions/builderAction"
import {createProjectC} from '../../actions/projectCAction';
import { withRouter } from 'react-router-dom';

import {
  Segment,
  Form,
  Button, 
 
  Header,
 
} from 'semantic-ui-react';
import TextInput from '../form/TextInput';
import TextArea from '../form/TextArea';
import SelectInput from '../form/SelectInput';
import DateInput from '../form/DateInput';
import { projectName } from './DataProjectName';

import {
  projectType,
  category,
  propertyTypeProj,
  city,
  state,
 //configuration,
  status,
 // transactionType,
  ageOfConstruction,
 // totalFloors,
  locality,
  amenitiesBasic,
  projectStatus,
} from './Data';



class ProjectCCreate extends Component {
  componentDidMount() {
    this.props.fetchBuilders();

  }
//** */

builderName = () => {
  return [{cname:'',_id:''},...this.props.buildersForData].map(value => ({
    key: value.cname,
    text: `${value.cname} ( ${value._id} )` ,
    value: value.cname,
  }));
};
builderId = () => {
  return [{cname:'',_id:''},...this.props.buildersForData].map(value => ({
    key: value._id,
    text: `${value._id} ( ${value.cname} )` ,
    value: value._id
  }));
};
  onSubmit = values => {
    this.props.createProjectC(values, this.props.history);
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }
  render() {


    // const { aproxValueOfWork, expectedPrice } =
    //   this.props.state.form &&
    //   this.props.state.form.ProjectCCreate != undefined &&
    //   this.props.state.form.ProjectCCreate.values != undefined &&
    //   this.props.state.form.ProjectCCreate.values;

    const { handleSubmit, pristine, reset, submitting } = this.props;


      return (
        <div style={{maxWidth:'700px',margin:'auto'}}>
          <Segment
          textAlign="center"
          //attached="bottom" 
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header
               // color="blue"
                content="Create Commercial Project"
              />
        </Segment>
        <div className="card-form ">
      <div className=" label" >

              <Form style={{ padding: 25 }}  onSubmit={handleSubmit(this.onSubmit)}>

              <Button
            color="teal"
            size="mini"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
              <Button
                size="mini"
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
              <br /><br />
              <label>Project's RERA No.</label>
            <Field
              name="reraNo"
              type="text"
              component={TextInput}
              placeholder="Enter RERA Number"
            />
              <label >Project Type</label>
              <Field
                name="projectType"
                placeholder="Project Type"
                type="text"
                options={projectType()}
                //search={true}
                component={SelectInput}
              />
             <label >Category</label>
              <Field
                name="category"
                placeholder="Category"
                type="text"
                options={category()}
                //search={true}
                component={SelectInput}
              />
              <label >Property Type</label>
              <Field
                name="propertyTypeProj"
                placeholder="Property Type"
                type="text"
                //search={true}
                multiple={true}
                options={propertyTypeProj()}
                component={SelectInput}
              />
                <label >Project Name </label>
                <Field
                  name="projectName"
                  placeholder="Project Name"
                  type="text"
                  options={projectName()}
                  search={true}
                  component={SelectInput}
                />
                <label >Project Name </label>
        <Field
          name="projectName"
          placeholder="Project Name"
          type="text"
          component={TextInput}
        />
                <label>Address</label>
    <Field
      name="address"
      type="text"
      component={TextInput}
      placeholder="Address"
    />
            <label >Locality</label>
            <Field
              name="locality"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              component={SelectInput}
            />
             <label>City/District</label>
            <Field
              //search={true}
              name="city"
              type="text"
              component={SelectInput}
              options={city()}
              placeholder="City/District"
            />
            <label>Pincode</label>
            <Field
              name="pincode"
              type="text"
              component={TextInput}
              placeholder="Pincode"
            />
            <label>State</label>
            <Field
              //search={true}
              name="state"
              type="text"
              component={SelectInput}
              options={state()}
              placeholder="State"
            />
            <label >Location On Map</label>
            <Field
              name="locationOnMap"
              type="text"
              component={TextInput}
              placeholder="Location On Map"
            />

                <label >Status</label>
                <Field
                  //search={true}
                  name="status"
                  type="text"
                  component={SelectInput}
                  options={status()}
                  placeholder="Status"
                />
                <label >Possession By Date</label>
                <Field
                  name="possession"
                  type="text"
                  component={DateInput}
                  dateFormat="dd-MM-yyyy "
                  placeholder="Possession By Date "
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />

                   <label >Age Of Construction</label>
                <Field
                  //search={true}
                  name="ageOfConstruction"
                  type="text"
                  component={SelectInput}
                  options={ageOfConstruction()}
                  placeholder="Age Of Construction"
                />



                {/* <label >Configuration</label>
                <Field
                  name="configuration"
                  placeholder="Configuration"
                  type="text"
                  //search={true}
                  multiple={true}
                  options={configuration()}
                  component={SelectInput}
                /> */}
                <label >Towers</label>
                <Field
                  name="towers"
                  type="text"
                  component={TextInput}
                  placeholder="Towers"
                />
                <label >Units</label>
                <Field
                  name="units"
                  type="text"
                  component={TextInput}
                  placeholder="Units"
                />


                <label >Total Floors</label>
                <Field
                  name="totalFloors"
                  placeholder="Total Floors"
                  type="text"
                  component={TextInput}
                />

                <label >Lifts In The Tower</label>
                <Field
                  name="liftsInTheTower"
                  placeholder="Number of Lifts In The Tower"
                  type="text"
                  component={TextInput}
                />
                <label >Amenities Basic</label>
                <Field
                  name="amenitiesBasic"
                  placeholder="Amenities Basic"
                  type="text"
                  options={amenitiesBasic()}
                  //search={true}
                  multiple={true}
                  component={SelectInput}
                />
                <label >Builder's ID</label>
        <Field
        name="builderId"
        placeholder="Builder's ID"
        type="text"
        search={true}
        component={SelectInput}
        options={this.builderId()}
        />
        <label >Builder's Name</label>
        <Field
        name="builderName"
        placeholder="Builder's Name"
        type="text"
        search={true}
        component={SelectInput}
        options={this.builderName()}
        />

                <label >Landmarks</label>
                <Field
                  name="landmarks"
                  placeholder="Landmarks"
                  type="text"
                  component={TextInput}
                />
                <label >About Project</label>
                <Field
                  name="description"
                  placeholder="About Project"
                  type="text"
                  component={TextArea}
                  rows={3}
                />
                <label >Project Status</label>
             <Field
               //search={true}
               name="projectStatus"
               type="text"
               component={SelectInput}
               options={projectStatus()}
               placeholder="Project Status"
             />
                <br />

                <Button
                  color="olive"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Submit
                </Button>
                <Button
                  color="orange"
                  type="button"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Clear Values
                </Button>
              </Form>
              <br/><br/><br/>
            </div>
            </div>
            </div>
      );


  }
}
const mapStateToProps = state => {
  return {
    state,
    buildersForData:state.builders.buildersForData,
    userA: state.auth.userA
  };
};
export default connect(
  mapStateToProps,
  {createProjectC,fetchBuilders}
)(
  reduxForm({
    form: 'ProjectCCreate', // a unique identifier for this form
  //  validate
  })(withRouter(ProjectCCreate))
);
