import React, { Component } from "react";
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";

import axios from "axios";
import { toastr } from "react-redux-toastr";

import format from "date-fns/format";
import Paginator from "../paginator/Paginator";
import {searchStatics} from "../../actions/staticAction";


import { openModal } from "../../actions/modalAction";
import {
  Form,
  Icon,
  Button,
  Confirm,
} from "semantic-ui-react";



class StaticSearchList extends Component {
  state = {
    pageO:'',
    id: "",
    open: false,
    result: "show the modal to capture a result"
  };
  componentDidMount() {
    const {offset, limit} = this.props;
      
      const values =
        (this.props.form !== undefined && this.props.form.values) || {};
    
    this.props.searchStatics(values, limit,offset);
     
  }

  handleSearchStatic = () => {
    this.props.openModal("StaticSearchModal");
  };


 //****************** */ 
 show = id => {
    
  this.setState({ open: true, id: id });
};
handleConfirm = async () => {
  const did = this.state.id;

  const {offset, limit} = this.props;
    
  const values =
    (this.props.form !== undefined && this.props.form.values) || {};

  
 const res = await axios.delete(`/api/StaticDelete/${did}`);

  if(res.data.msg ==='success'){
    this.props.searchStatics(values, limit,offset); 
    this.setState({ result: "confirmed", open: false });
    toastr.success('Deleted Successfully')
  }
};
handleCancel = () => this.setState({ result: "cancelled", open: false });     
//^^^^^^^^^^^^^^^^^^^^^^^^^
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//***************************
 
  renderList=(item)=> {
    const {
      start,
       expire,
       adFor,
       adCategory,
       adLocation,
       adSubLocation,
       position,
       dealerName,
       dealer,
       
              //adFor,

              propOrProjId,
      				propertyFor,
      				propertyType,
      				bedroom,
      				additionalRooms,
      				expectedPrice,
      				expectedRent,
              carpetArea,
              area,
              areaUnit,
      				projectName,
      				//projectId,
      				locality,
      			//	createdAt,

      				_id
    } = item;

//************************** render active/inactive/expired
const startOn= Date.parse(start)
const expireOn = (Date.parse(expire))
const now = Date.now()
//**************************


const renderStatus=() => {
  if ((now<startOn)){
    return (<span >
      Inactive
     </span>)
  }

  if( (now>startOn && now<expireOn )){
    return (<span >
       Active
     </span>)
  }
  if ((now>expireOn)){
  return (<span className='color-red'>
      Expired
     </span>)
  }
  return (<span >
    No Status
   </span>)
  }


  //**************************
const renderDealer = () =>
(dealerName)  ? (
  <div >
  {dealerName && (
  <div className='row'>
    <div className='side'>
    <b>Dealer</b>
    </div>
    <div className='main'>
    <b>{dealerName}{' '}</b>{' '}
                      <span className='border font-16B pxy-05 color-dodgerblue'>
                        Static Ad Booked
                      </span>
    </div>

  </div>
  )}
</div>
      ) : (
        <span className='border font-16B pxy-05 color-red'>
        Static Ad Not Booked
      </span>
      );


  //**************************
    const renderDetailButton=() => {

      if(((adFor==="Property SALE") || (adFor==="Property RENT")) && adCategory==='Residential' ){
        return (<Button
          inverted
         //floated="right"
         size="mini"
         color="blue"
           type="button"
           as={Link}
           to={`/propresi/PropResiDetail/${propOrProjId}`}
         >
          Detail
         </Button>)
      }
      if(((adFor==="Property SALE") || (adFor==="Property RENT")) && adCategory==='Commercial' ){
        return (<Button
          inverted
         //floated="right"
         size="mini"
         color="blue"
           type="button"
           as={Link}
           to={`/propcom/PropComDetail/${propOrProjId}`}
         >
          Detail
         </Button>)
      }

            }

            //**************************



    //**************************
    const roomOrBHK = (adCategory==='Residential') ? 'BHK' :'Room'
    const renderadditionalRooms = () => {
			  return additionalRooms.map(item => {
				return (
				  <span key={item}>
					{" "}
			 {item}|
				  </span>
				);
			  });
			};
      const price = (value) => {
         var val = Math.abs(value)
        if (val >= 10000000) {
          val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
          val = (val / 100000).toFixed(2) + ' Lac';
        }
        /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
        return val;
      }

    //const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));

    return (
      <div key={_id}>
            <div className='card' style={{padding:'10px',marginTop:'0px',border:'solid 2px dodgerblue',}} >
{/*ad detqils start*/}
<div ><h6 className='border-bottom'style={{marginTop:'5px',marginBottom:'5px'}}>{(adFor !== 'Dealer') && adCategory}{' '} {' '} {adFor}{' '}
At {adLocation}
<span style={{color:'dodgerblue',marginLeft:'20px'}}>{ renderStatus()}</span>
</h6></div>

<div style={{fontSize:'1rem'}}>
      <div >
				{start && (
				  <div className='row'>
					<div className='side'>
					  <b>Duration</b>
					</div>
					<div className='main'>
					  <b>{ format(new Date(start), "dd-MM-yyyy")}{' '}To{' '}{ format(new Date(expire), "dd-MM-yyyy")}{' '}   </b>
					</div>

				  </div>
				)}
			  </div>
        
          

			  {renderDealer()}
{/* static ad location start */}

  <div >
  {adFor && (
  <div className='row'>
  <div className='side'>
  <b>Ad Location</b>
  </div>
  <div className='main'>
  <b>

  <div>
  {(adFor !== 'Dealer') && adCategory && (

  <span >
  {adCategory}
  </span>)

  }{' '}

  {adFor && (

  <span >
  {adFor}
  </span>)

  }{' '}
 
  {adLocation && (

  <span >
  {adLocation}
  </span>)

  }{' '}
  {/*  */}
  {((adLocation==='STATIC ALL')||(adLocation==='STATIC SECTOR')) 
  && adSubLocation && (
  <span >
  {adSubLocation}
  </span>)

  }{' '}
  {position && (

  <span >
  On Position  {position}
  </span>)

  }{' '}
          {/*  */}



                    </div>

        </b>
      </div>

      </div>
    )}
    </div>
{/* static ad location end */}
    <div >
    {projectName && (
      <div className='row'>
      <div className='side'>
        <b>Location</b>
      </div>
      <div className='main'>
        <b>

        <div>
        {bedroom && (

            <span >
              {bedroom} {' '}{roomOrBHK}
            </span>)

        }{' '}
        {additionalRooms && additionalRooms.length >0 && (

          <span >
            {renderadditionalRooms()}
          </span>)

        }{' '}
        { carpetArea > 0 && (

            <span >
              {carpetArea} sq ft
            </span>)

        }{' '}
        { area >0 && (

        <span >
        {area}
        </span>)

        }{' '}
        {areaUnit && (

          <span >
            {areaUnit}
          </span>)

            }{' '}
            {propertyType && (

                <span >
                  {propertyType}
                </span>)

        }{' '}
        {propertyFor && (

          <span >
          For  {propertyFor}
          </span>)

        }{' '}
        {expectedPrice>0 && (

        <span >
        For  <Icon className="rupee" ></Icon>
                      {price(expectedPrice)}{' '}In
        </span>)

        }{' '}
        {expectedRent>0 && (

          <span >
          For  <Icon className="rupee" ></Icon>
        {expectedRent}{' '}In
          </span>)

        }{' '}
        {projectName && (

          <span >
        {' '}{projectName}
          </span>)

        }{' '}
        {locality && (

          <span >
           {locality} Dwarka, New Delhi
          </span>)

        }{' '}
{renderDetailButton()}
                    </div>

        </b>
      </div>

      </div>
    )}
    </div>

    {/*  */}
    <div className='row'> <Button
            // basic
            inverted
            floated="left"
            size="mini"
            color="green"
            type="button"
            as={Link}
            to={`/static/StaticEdit/${_id}`}
          >
            Edit
          </Button>
          <Button
            // basic
            inverted
            floated="right"
            size="mini"
            color="red"
            type="button"
            onClick={() => this.show(_id)}
          >
            Delete
          </Button>
          </div>
{/*  */}
    </div>


            </div>
      </div>


    );
  }

  back = () => {
    const {
      offset,
      limit
     
    } = this.props;
   
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
      return;
    }

    this.props.searchStatics(values, limit, (offset - limit));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offset,
      limit,
      count
      
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
      return;
    }

    this.props.searchStatics(values, limit, (offset + limit));
    window.scroll(0,0)
  };

  handleGoToPage = () => {
    const {
      
      limit,
      count
      
    } = this.props;
  
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
      // use Math.abs for strict equality ===  to work and safe guard against negative value from user
      if (Math.abs(this.state.pageO) === 0) {
        return; 
      }
  
    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)
  
  
    this.props.searchStatics(values, limit, (page * limit));
      window.scroll(0,0)
  
  }
  
  //****************** */
  renderPaginator=()=> {
    if (this.props.statics.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }


  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }


  render() {
    const { open, } = this.state;
    return (

      <div className='maindiv-820'>
        <Confirm
            size="mini"
            open={open}
            onCancel={this.handleCancel}
            onConfirm={() => this.handleConfirm()}
            style={{padding:'50px'}}
          />
      <div >


      <div>
  {/**/}
  <div className='cashbook-H box-shadow'>
  
  <div
  style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
  onClick={this.handleSearchStatic}>
        <span className='icon-button border1' > <Icon name='search' /></span >
      </div>
  
  
  
  <div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
  <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
  All Static Advertisement ({this.props.count})
  </div>
  </div>
  
  
  </div>
  {/**/}

  {this.renderLoading()}
  {this.props.statics.map(this.renderList)}

  
<div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()} 
<div className="disflex-paginator mb-0">
  <div className= 'font-paginator'>Go To Page</div>
<Form size="mini" >

            <Form.Input

                placeholder="Enter page number"
                name="pageO"
                type="number"
                value={this.state.pageO}
                onChange={this.onChange}

              />
            </Form>
            <Button size='mini' color='blue' onClick={this.handleGoToPage} >
          Submit
        </Button>
</div>
</div>

<br/><br/><br/><br/>
  <div></div>
  <div></div>
  </div>
  <div className='nodisplay'>
  {/* side grid space*/}
  </div>


  </div>
  </div>

    );
  }
}


const mapStateToProps = ({ statics, auth,form }) => {
  const { fetching,limit, offset, count } = statics;
  return {
    userA: auth.userA,
    fetching,
    limit,
    offset,
    count,
    form:form.StaticSearch,
    statics: statics.statics,

  };
};

export default connect(
  mapStateToProps,
  {searchStatics,openModal}
)(StaticSearchList);
