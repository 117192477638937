import axios from "axios";
import { toastr } from "react-redux-toastr";
import {openModal, closeModal } from "./modalAction";

import {
  FETCH_STATICS,
  FETCH_STATIC,
  SEARCH_STATICS,
  FETCHING_STATICS,
} from "./types";

export const resetFetching = () => async dispatch => {
  dispatch({ type:FETCHING_STATICS, payload: true });
};

//search  Statics
export const searchStatics = (
  values,
  limit,
  offset
) => async dispatch => {

  try {
    const res = await axios.post("/api/StaticSearch", {
      values,
      limit,
      offset
    });

    dispatch({ type: SEARCH_STATICS, payload: res.data });
    dispatch({ type:FETCHING_STATICS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
};
//*********************************
//fetch all Statics prop for Advertisement by one Dealer
export const fetchStaticPropAdByDealer = ( values,
  limitP,
  offsetP ) => async dispatch => {

  try {
    const res = await axios.post("/api/StaticPropAdByDealer",{
      values,
      limitP,
      offsetP
    } );

    dispatch({ type: FETCH_STATICS, payload: res.data });
    dispatch({ type:FETCHING_STATICS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again fetchStatics");
  }
};
//*****************************
///fetch all Statics prop for Advertisement by one Dealer
export const fetchStaticDealerAdByDealer = ( values,
  limitD,
  offsetD ) => async dispatch => {

  try {
    const res = await axios.post("/api/StaticDealerAdByDealer",{
      values,
      limitD,
      offsetD
    } );

    dispatch({ type: FETCH_STATICS, payload: res.data });
    dispatch({ type:FETCHING_STATICS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again fetchStatics");
  }
};

//fetch single Static
export const fetchStatic = id => async dispatch => {
  try {
    const res = await axios.get(`/api/StaticDetail/${id}`);

    dispatch({ type: FETCH_STATIC, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
};
//delete Static
export const deleteStatic = (id) => async dispatch => {
  try {
    const res = await axios.delete(`/api/StaticDelete/${id}`);
    if(res.data.msg ==='success'){
      toastr.success('Static Ad Deleted')
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
};





//update Static
export const updateStatic = (id, values) => async dispatch => {
  try {
    const res = await axios.put(`/api/StaticEdit/${id}`, values);
    
    dispatch({ type: FETCH_STATIC, payload: null });

    if(res.data.msg ==='success'){
      toastr.success('Static Ad Updated')
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
};


//########################################################################
// create Static
export const createStatic = ( values) => async dispatch => {
  try {
  
    const res = await axios.post(`/api/StaticCreate`, values);
    if(res.data.msg ==='success'){
      toastr.success('Static Ad Successfully Created');
    }
    
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
};

