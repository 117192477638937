import React from "react";
import { connect } from "react-redux";
 
 import LoginModal from './LoginModal';
 import ImageZoomModal from './ImageZoomModal'; 
 import ShowImagesModal from './ShowImagesModal';
 import ShowVideoModal from './ShowVideoModal';

 import ShowImagesForEditModal from './ShowImagesForEditModal';
 import ShowVideoForEditModal from './ShowVideoForEditModal';
 
 import LoginModalDealerSearch from './LoginModalDealerSearch';
 import MessageModal from './MessageModal';
import SearchCompanyModal from "./SearchCompanyModal";
import SearchBuilderModal from "./SearchBuilderModal";
import SearchUsersModal from "./SearchUsersModal";

import SearchBuilderFloorSaleModal from "./SearchBuilderFloorSaleModal";
import SearchPropResiSaleModal from "./SearchPropResiSaleModal";

import SearchPropResiRentModal from "./SearchPropResiRentModal";

import SearchPropComSaleModal from "./SearchPropComSaleModal";

import SearchPropComRentModal from "./SearchPropComRentModal";

import SearchReqComModal from "./SearchReqComModal";
import SearchReqComUnlockedModal from "./SearchReqComUnlockedModal";

import SearchReqResiModal from "./SearchReqResiModal";
import SearchReqResiUnlockedModal from "./SearchReqResiUnlockedModal";


import SearchProjectRModal from "./SearchProjectRModal";

import SearchProjectCModal from "./SearchProjectCModal";

import StaticSearchModal from "./StaticSearchModal";
import SearchStaticPropertyModal from "./SearchStaticPropertyModal";
import SearchStaticDealerModal from "./SearchStaticDealerModal";

import SearchContactReqsByPartyModal from "./SearchContactReqsByPartyModal";
import SearchContactReqsForDealerModal from "./SearchContactReqsForDealerModal";
import SearchAdByDealerModal from "./SearchAdByDealerModal";
import SearchCreditAddModal from "./SearchCreditAddModal";

import TermsAndConditionsModal from './TermsAndConditionsModal'
import CookiesPolicyModal from './CookiesPolicyModal'
import PrivacyPolicyModal from './PrivacyPolicyModal'

const modalLookup = { 
   LoginModal,
   ImageZoomModal,
   ShowImagesModal,
   ShowVideoModal,
   
   ShowImagesForEditModal,
   ShowVideoForEditModal,

   LoginModalDealerSearch,
   MessageModal,
  SearchCompanyModal,
  SearchBuilderModal,
  SearchUsersModal,

  SearchBuilderFloorSaleModal,
  SearchPropResiSaleModal,

  SearchPropResiRentModal,


  SearchPropComSaleModal,

  SearchPropComRentModal,

  SearchReqResiModal,
  SearchReqResiUnlockedModal,

  SearchReqComModal,
  SearchReqComUnlockedModal,

  SearchProjectCModal,

  SearchProjectRModal,

  StaticSearchModal,
  SearchStaticPropertyModal,
  SearchStaticDealerModal,

  SearchAdByDealerModal,
  SearchContactReqsForDealerModal,
  SearchContactReqsByPartyModal,

  SearchCreditAddModal,

  CookiesPolicyModal,
  PrivacyPolicyModal,
  TermsAndConditionsModal,
  
};

const mapState = state => ({
  currentModal: state.modals
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
