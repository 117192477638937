import React from 'react';

import { Link, } from "react-router-dom";

import {
  Icon,
} from 'semantic-ui-react';


const ActivityLink = props => {

const linkHeadClass = 'linkFP-head activity-head-padding'
const linkClass = 'linkFP textAlign-center'
      const {uid,_id,uname,userTypeW} = props.val    
      const renderDealerLink=()=>{
        if(userTypeW ==="Dealer"){
        return(
<div style={{maxWidth:'400px',margin:'auto'}}>
      <div className='card-attached '>


<div className={linkClass}
style={{backgroundColor:'dodgerblue',padding:'5px',cursor:'default'}}
>

<b><span style={{color:'white',}}>{uname}{' '}</span></b>

</div>

<div className={linkClass}>
<Link to={`/activity/CreditDetailList/${_id}`} >
          <b>Credit Detail</b>
          </Link>
</div>
<div className={linkClass}>
<Link to={`/company/UserDetail/${uid}`} >
<b>User Details</b>
</Link>
</div>

<div className={linkClass}>
<Link to={`/passchange`} >
<Icon name='settings' />
<b>Change Password</b>
</Link>
</div>

<div className={linkHeadClass} >
REQUESTS</div>
<div className={linkClass}>
<Link to={`/activity/ContactReqForDealerList/${_id}`} >
<b>Contact Requests Received</b>
</Link>
</div>

<div className={linkHeadClass} >
ADVERTISEMENTS</div>


<div className={linkClass}>
<Link to={`/activity/AdByFinancerList/${_id}`} >
<b>Financer Advertisement Details</b>
</Link>
</div>

<div className={linkClass}>
<Link to={`/activity/AdByDealerList/${_id}`} >
          <b>Dynamic Advertisement Details</b>
        </Link>
</div>
<div className={linkClass}>
<Link to={`/static/StaticDealerAdByDealer/${_id}`} >
          <b>Static Dealer Advertisement Details</b>
        </Link>
</div>
<div className={linkClass}>
<Link to={`/static/StaticPropAdByDealer/${_id}`} >
          <b>Static Property Advertisement Details</b>
        </Link>
</div>


<div className={linkHeadClass} >
REQUIREMENTS</div>
<div className={linkClass}>
<Link to={`/reqresi/ReqResiUnlockedList/${uid}`} >
          <b>Unlocked Residential Requirements</b>
        </Link>
</div>
<div className={linkClass}>
<Link to={`/reqcom/ReqComUnlockedList/${uid}`} >
          <b>Unlocked Commercial Requirements</b>
        </Link>
</div>

</div>
      
    </div>
        )
        }
      }

      //@@@@

      const renderBuilderLink=()=>{
        if(userTypeW ==="Builder"){
        return(
<div style={{maxWidth:'400px',margin:'auto'}}>
      <div className='card-attached '>


<div className={linkClass}
style={{backgroundColor:'dodgerblue',padding:'5px',cursor:'default'}}
>

<b><span style={{color:'white',}}>{uname}{' '}</span></b>

</div>


<div className={linkClass}>
<Link to={`/company/UserDetail/${uid}`} >
<b>User Details</b>
</Link>
</div>

<div className={linkClass}>
<Link to={`/passchange`} >
<Icon name='settings' />
<b>Change Password</b>
</Link>
</div>

<div className={linkHeadClass} >
REQUESTS</div>
<div className={linkClass}>
<Link to={`/activity/ContactReqForDealerList/${_id}`} >
<b>Contact Requests Received</b>
</Link>
</div>




</div>
      
    </div>
        )
        }
      }
      //****** */
  return (
    <>
    {renderBuilderLink()}
    {renderDealerLink()}
    </>
  );
}

export default ActivityLink
