import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {fetchBuilder} from "../../actions/builderAction";
import { openModal } from "../../actions/modalAction";
import { openPopup } from "../../actions/popupAction";
import {fetchProjectRForBuilder} from "../../actions/projectRAction";
import {getUserId} from "../../actions/authAction";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import ProjectRSearchListBuilder from "../projectR/ProjectRSearchListBuilder";
import format from "date-fns/format";
import Loading from "../common/Loading"; 
import ActivityLink from "../companys/ActivityLink";

import {
  Button,
  Divider,
  Modal,
  Image,
} from "semantic-ui-react";
import ViewImages from "./ViewImages";

 

class OtherBuilderDetail extends Component {
  state = {
    modalOpenImg: false,
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchBuilder(id);
    this.props.getUserId(id)
   const value1={id:id,status:['New launch','Under Construction']};
   const value2={id:id,status:['Ready To Move']};
   const value3={id:id,status:['Upcoming']};
    this.props.fetchProjectRForBuilder(value1)
    this.props.fetchProjectRForBuilder(value2)
    this.props.fetchProjectRForBuilder(value3)
  }
//**********************************
handleOpenImg = () => {
  this.setState({ modalOpenImg: true,})
}

handleCloseImg = () => this.setState({ modalOpenImg: false })
//*********** */
  handleOpenModal = (url) => {   
    this.props.openModal("ImageZoomModal" , {imageURL:url});
  };
  handleOpenPopup = (ph , col) => {
    const message= `${ph} Mobile Number Copied` 
    this.props.openPopup("CopyPhoneFixedPopup" , {message:message, color:col,});
  };
  

//************************ */ 1 <= req.body.viewCount < 40
  render() {
    

    // const rendertypeOfDealings = () => {
    //   return typeOfDealings.map(item => {
    //     return (
    //       <span key={item}>
    //         {" "}
    //         <b>{item} |</b>
    //       </span>
    //     );
    //   });
    // };
    // const rendertypeOfPropertyDealsIn = () => {
    //   return typeOfPropertyDealsIn.map(item => {
    //     return (
    //       <span key={item}>
    //         {" "}
    //         <b>{item} |</b>
    //       </span>
    //     );
    //   });
    // };


    if (!this.props.builder) {
      return (<Loading/>)
    }
    const {
      reraNo,
      cname,
      cmobile1,
      cmobile2,
      cemail,
      website,
      cimage,
      address,
      locality,
      city,
      state,
      pincode,
      operatingSince,
      nameCP1,
      mobileCP1,
      //emailCP1,
      designationCP1,
      photoCP1,
      nameCP2,
      mobileCP2,
     // emailCP2,
      designationCP2,
      photoCP2,
      // typeOfDealings,
      // typeOfPropertyDealsIn,
      //areaOfOperation,
      description,
      _id
    } = this.props.builder;


     
  
    
          //###########################

          const rendermobileCP1 = () => 
             (
            <span >
            <span >
              {mobileCP1}
            </span>
            <span className='nodisplay-tab'>
               <CopyToClipboard text={mobileCP1}  >
               <span className="icon-button"  onClick= {() => this.handleOpenPopup(mobileCP1,'blue' )}>
            <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
              </CopyToClipboard>
              </span>
              </span>
              )
              const rendermobileCP2 = () => 
                  (
                  <span >
                  <span >
                    {mobileCP2}
                  </span>
                  <span className='nodisplay-tab'>
                     <CopyToClipboard text={mobileCP2}  > 
                     <span className="icon-button"  onClick= {() => this.handleOpenPopup(mobileCP2,'teal' )}>
                  <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
                  </span>
                    </CopyToClipboard>
                    </span>
                    </span>
                   )
         
                   const renderCompanyMobile = () => 
                     (
                     < React.Fragment>
                      <div >
              {cmobile1 && (
                <div className='row'>
                  <div className='side'>
                    <b>Phone</b>
                  </div>
                  <div className='main'>
                    <b>{cmobile1}{' '}</b>
                    {cmobile2 && (

                          <b>,{' '} {cmobile2}</b>

                      )}
                  </div>

                      </div>
                    )}
                  </div>

                  <div >
                  {cemail && (
                    <div className='row'>
                      <div className='side'>
                        <b>Email</b>
                      </div>
                      <div className='main'>
                        <b>{cemail}</b>
                      </div>

                    </div>
                  )}
                  </div>
                   </React.Fragment>
                       )
  //******************
  const uid= this.props.userD !== null && this.props.userD._id
  const{ uname,userTypeW}= this.props.userD !== null && this.props.userD
  //**********************
  const renderButton = () =>
    this.props.userA !== null &&
      this.props.userA.userType === "admin" ? (
        <Button.Group size="mini" floated="right">
          <Button
        // basic
        // inverted
        floated="right"
        color="blue"
        type="button"
        onClick={this.handleOpenImg}
        >
        View Image
        </Button>
          <Button
            // basic
            // inverted
            floated="right"
            size="mini"
            color="orange"
            type="button"
            as={Link}
            to={`/builder/addimage/${_id}`}
          >
            Add Image
          </Button>
          <Button
            // basic
            // inverted
            floated="right"
            size="mini"
            color="olive"
            type="button"
            as={Link}
            to={`/builder/editbuilder/${_id}`}
          >
            Edit
          </Button>
        </Button.Group>
    ) : (
      <div></div>
    );
  
//**********************

    return (
      
      <div className='maindiv-768'>

{/**/}
<div>
      <Modal
          // trigger={<Button onClick={this.handleOpen}>Show Modal</Button>}
          open={this.state.modalOpenImg}
          onClose={this.handleCloseImg}
          basic
          closeOnEscape={false}
          closeOnDimmerClick={false}
          // size='small'
          style = {{
            backgroundColor:'black',
        margin:'0px',
        padding: '0px',
            maxWidth:'768px'
          }}
        >

          <Modal.Content>
      <ViewImages
      id = {_id}
     handleCloseImg ={this.handleCloseImg}
      />
          </Modal.Content>
          
        </Modal>
        </div>
        {/**/}
          {/* start */}

      
        
      <div   >
                
                {/*  */}
      <div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
      <div className='heading-2'>
      <span  > {cname}</span>
      </div>
      </div>
                {/*  */}




            <div className='card-attached border-sb pxy-20'>
{/*  */}
<div className='display-flex'  style= {{ marginBottom:'2px',justifyContent:'center'}} >
			
      {renderButton()}
			</div>
             <ActivityLink val={{uid,_id,uname,userTypeW}}/>
             <br/>
		{/*  */}
{/*  */}
<div className='media-600 ' style={{display:'flex',justifyContent:'center'}}> {/*start of div containing cp1 and cp2 detail */}

{ nameCP1 &&  (<div className='card-cp' > {/* cp1 detail*/}
  <div className='flex-value-DA' style={{display:'flex',flexDirection:'column'}}>
  <img 
  className="icon-button"
  onClick={() => this.handleOpenModal(photoCP1)}
  src={`${photoCP1}`} alt="Photo_First contact person" 
  style={{width:"100px", height:"100px",padding:'5px'}} />
  </div>


<div className='flex-value-DB 'style={{padding:'10px',fontWeight:"bold"}}>


<div>{nameCP1 && (
<div >
  {nameCP1}
</div>
)}
</div>  {/* */}

<div>{designationCP1 && (
<div>{designationCP1}</div>
)}</div>  {/* */}

<div>{mobileCP1 && (
rendermobileCP1()
)}</div>  {/* */}

  </div>
  </div>)}
{/*end cp1 details */}

  { nameCP2 &&  (<div className='card-cp' >{/* cp2 detail*/}
  <div className='flex-value-DA' style={{display:'flex',flexDirection:'column'}}>
  <img
  className="icon-button"
  onClick={() => this.handleOpenModal(photoCP2)}
  src={`${photoCP2}`} alt="Photo_Second contact person" 
  style={{width:"100px", height:"100px",padding:'5px'}} />
  </div>


<div className='flex-value-DB 'style={{padding:'10px',fontWeight:"bold"}}>


<div>{nameCP2 && (
<div >
  {nameCP2}
</div>
)}
</div>  {/* */}
<div>{designationCP2 && (
<div>{designationCP2}</div>
)}</div>  {/* */}
<div>{mobileCP2 && (
rendermobileCP2()
)}</div>  {/* */}

  </div>
  </div>)}

</div> 

    {/*end of div containing cp1 and cp2 detail */}
    

              <br />
             {/*  */}
		
			<div className='display-flex'  style= {{ marginBottom:'20px'}} >
			
			</div>
		
		{/*  */}
              <div>
              
              <Divider fitted />
                <div >
                {address && (
                  <div className='row'>
                    <div className='side'>
                    <Image
                  floated="left"
                  size="tiny"
                  src={`${cimage}`}
                />
                    </div>
                    <div className='main'style={{ display:'flex',alignItems:'center'}} >
                      <b>{address}{' '}{locality}{' '}{city}{' '}{state}{' '}{pincode}</b>
                    </div>

                  </div>
                )}
              </div>
              {renderCompanyMobile()}

            
          <div >
          {website && (
            <div className='row'>
              <div className='side'>
                <b>Website</b>
              </div>
              <div className='main'>
              
               <a  href={website} target='_blank'><b>{website}</b></a>
              </div>
             
            </div>
          )}
        </div>
        <div >
{reraNo && (
<div className='row'>
<div className='side'>
<b>Company's RERA No.</b>
</div>
<div className='main'>
<b>{reraNo}</b>
</div>

</div>
)}
</div>       
        <div >
        {operatingSince && (
          <div className='row'>
            <div className='side'>
              <b>Operating Since</b>
            </div>
            <div className='main'>
              <b>{format(new Date(operatingSince), "dd-MM-yyyy")}</b>
            </div>

          </div>
        )}
      </div>



              {/* <div >
               {typeOfDealings != null &&
                            typeOfDealings.length >= 1 && (
                <div className='row'>
                  <div className='side'>
                    <b>Type of Dealings</b>
                  </div>
                  <div className='main'>
                    {rendertypeOfDealings()}
                  </div>

                </div>
              )}
             </div> */}
             {/* <div >
             {typeOfPropertyDealsIn != null &&
                          typeOfPropertyDealsIn.length >= 1 && (
                <div className='row'>
                  <div className='side'>
                    <b>Type Of Property deals in</b>
                  </div>
                  <div className='main'>
                     {rendertypeOfPropertyDealsIn()}
                  </div>

                </div>
              )}
             </div> */}

             <div >
             {description && (
               <div className='row'>
                 <div className='side'>
                   <b>About Company</b>
                 </div>
                 <div className='main'>
                   <b>{description}</b>
                 </div>

               </div>
             )}
             </div>


              </div>

              
              </div>
              
              <div className='card-attached border-sb border-db-bottom '  >
              <ProjectRSearchListBuilder 
              heading={'On Going / New Projects'}
              project={this.props.projectRsBuilderOngoing}  />
              <ProjectRSearchListBuilder 
               heading={'Past Projects'}
              project={this.props.projectRsBuilderPast}    />
              <ProjectRSearchListBuilder 
               heading={'Upcoming Projects'}
              project={this.props.projectRsBuilderUpcoming}    />
      
              </div>

          </div>
        



        
      
      {/* end */}
      
<br/><br/><br/><br/>
{/* closing first div */}
      </div> 

    );
  }
}

function mapStateToProps(state) {
  
  return { 
  projectRsBuilderOngoing:state.projectRs.projectRsBuilderOngoing,
  projectRsBuilderPast:state.projectRs.projectRsBuilderPast,
  projectRsBuilderUpcoming:state.projectRs.projectRsBuilderUpcoming,
  builder: state.builders.builder ,
  userA :state.auth.userA,
  userD: state.auth.userD, 
  };
}

export default connect(
  mapStateToProps,
  {getUserId,fetchProjectRForBuilder,fetchBuilder,openPopup, openModal}
)(withRouter(OtherBuilderDetail));
