import React, { Component } from "react";
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading"; 
import {fetchAdByDealer,} from "../../actions/dealerSuggestedAction";
import { getUserForAuth } from "../../actions/authAction";

import { openModal } from "../../actions/modalAction";
import {
  Icon,
  Button,
} from "semantic-ui-react";


class AdByDealerList extends Component {
  state = {
    pageO:'',
      id:'',
    };
  componentDidMount() {
    const {offset, limit} = this.props;
      const {id} = this.props.match.params;
    const val =
      (this.props.form !== undefined && this.props.form.values) || {};
  const values ={...val,id}
  
  if (this.props.auth.isAuthenticated) {
     
    this.props.fetchAdByDealer(values, limit,offset);
  }else{
    
    this.props.openModal('LoginModal')
  }
    
  }
    
    //**************** */
handleRefresh = () =>{
	const {offset,limit} = this.props;
    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    this.props.getUserForAuth()
    this.props.fetchAdByDealer(values, limit,offset);
    
}
	//**************** */
    handleModalOpen = (value) => this.setState({ modalOpen: true,valueRenew:value})

    handleModalClose = () => this.setState({ modalOpen: false })  //contact modal

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    handleSearchAdByDealer = () => {
    this.props.openModal("SearchAdByDealerModal");
    };
    //^^^^^^^^^^^^^^^^^^^^^^
    renderList=(item)=> {
    const {
    startedOn,
    expiredOn,
    creditUsed,
    //****************** */
    adFor,
    projectType,
    propOrProjId,
    propertyFor,
    propertyType,
    bedroom,
    additionalRooms,
    expectedPrice,
    expectedRent,
    carpetArea,
    area,
    areaUnit,
    projectName,
    //projectId,
    locality,
    //	createdAt,

    _id
    } = item;

    const {balanceCreditProp}=this.props.userA !== null && this.props.userA
    //************************** render active/inactive/expired
const valueRenew = {
  balanceCreditProp,
  expiredOn,
  creditUsed,
  id:_id
}
    const renderStatus=() => {


    if (Date.now()<Date.parse(startedOn)){
    return (<span >
    Inactive
    </span>)
    }
    if (Date.now()>Date.parse(startedOn) && Date.now()<Date.parse(expiredOn) ){
    return (<span >
    Active
    </span>)
    }
    if (Date.now()>Date.parse(expiredOn)){
    return (<span className='color-red'>
    Expired
    </span>)
    }
    return (<span >
    No Status
    </span>)
    }


    //**************************
    const roomOrBHK = (projectType==='Residential') ? 'BHK' :'Room'
    
    


    //**************************
    const renderDetailButton=() => {
    if(adFor==="Property" && projectType==='Residential' ){
    return (<Button
    inverted
    //floated="right"
    size="mini"
    color="blue"
    type="button"
    as={Link}
    to={`/propresi/PropResiDetail/${propOrProjId}`}
    >
    Detail
    </Button>)
    }
    if(adFor==="Property" && projectType==='Commercial' ){
    return (<Button
    inverted
    //floated="right"
    size="mini"
    color="blue"
    type="button"
    as={Link}
    to={`/propcom/PropComDetail/${propOrProjId}`}
    >
    Detail
    </Button>)
    }
    if(adFor==="Project" && projectType==='Residential' ){
    return (<Button
    inverted
    //floated="right"
    size="mini"
    color="blue"
    type="button"
    as={Link}
    to={`/projectR/ProjectRDetail/${propOrProjId}`}
    >
    Detail
    </Button>)
    }
    if(adFor==="Project" && projectType==='Commercial' ){
    return (<Button
    inverted
    //floated="right"
    size="mini"
    color="blue"
    type="button"
    as={Link}
    to={`/projectC/ProjectCDetail/${propOrProjId}`}
    >
    Detail
    </Button>)
    }



    }

    //**************************
    const renderadditionalRooms = () => {
    return additionalRooms.map(item => {
    return (
    <span key={item}>
    {" "}
    {item}|
    </span>
    );
    });
    };
    const price = (value) => {
    var val = Math.abs(value)
    if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac';
    }
    /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
    return val;
    }

    //const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));

    return (
    <div key={_id}>
    <div className='card' style={{padding:'10px',marginTop:'0px',border:'solid 2px dodgerblue',}} >
    {/*requester detqils start*/}
    {/*  */}
	<div className='border-bottom'  style= {{marginTop:'5px',marginBottom:'5px',}}>
	<div  className="heading" style= {{marginBottom:'10px'}} >
	<span >
	{projectType}{' '}{adFor}{' '}{propertyFor} 
	</span>
	</div>
	</div>
	{/*  */}
  
    <div style={{fontSize:'1rem'}}>
    <div >
    {startedOn && (
    <div className='row'>
    <div className='side'>
    <b>Duration</b>
    </div>
    <div className='main'>
    <b>{ format(new Date(startedOn), "dd-MM-yyyy")}{' '}To{' '}{ format(new Date(expiredOn), "dd-MM-yyyy")}{' '}
    <span style={{color:'dodgerblue',fontSize:'18px'}}>{ renderStatus()}</span>{' '}
    </b>
    </div>

    </div>
    )}
    </div>

    <div >
    {creditUsed>0 && (
    <div className='row'>
    <div className='side'>
    <b>Credit Used</b>
    </div>
    <div className='main'>
    <b>{creditUsed}{' '}</b>
    </div>

    </div>
    )}
    </div>




    <div >
    {projectName && (
    <div className='row'>
    <div className='side'>
    <b>Location</b>
    </div>
    <div className='main'>
    <b>

    <div>
    {bedroom && (

    <span >
    {bedroom} {' '}{roomOrBHK}
    </span>)

    }{' '}
    {additionalRooms.length >0 && (

    <span >
    {renderadditionalRooms()}
    </span>)

    }{' '}
    {carpetArea >0 && (

    <span >
    {carpetArea} sq ft
    </span>)

    }{' '}
    {area && (

    <span >
    {area}
    </span>)

    }{' '}
    {areaUnit && (

    <span >
    {areaUnit}
    </span>)

    }{' '}
    {propertyType && (

    <span >
    {propertyType}
    </span>)

    }{' '}
    {propertyFor && (

    <span >
    For  {propertyFor}
    </span>)

    }{' '}
    {expectedPrice>0 && (

    <span >
    For  <Icon className="rupee" ></Icon>
    {price(expectedPrice)}{' '}In
    </span>)

    }{' '}
    {expectedRent>0 && (

    <span >
    For  <Icon className="rupee" ></Icon>
    {expectedRent}{' '}In
    </span>)

    }{' '}
    {projectName && (

    <span >
    {' '}{projectName}
    </span>)

    }{' '}
    {locality && (

    <span >
    {locality} Dwarka, New Delhi
    </span>)

    }{' '}
    {renderDetailButton()}
    </div>

    </b>
    </div>

    </div>
    )}
    </div>

    </div>


    </div>
    </div>


    );
    }

  back = () => {
    const {
      offset,
      limit
      
    } = this.props;
    //const values = this.props.form.ProjectSearch.values;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
      return;
    }

    this.props.fetchAdByDealer(values, limit, (offset - limit));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offset,
      limit,
      count
    
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
      return;
    }

    this.props.fetchAdByDealer(values, limit, (offset + limit));
    window.scroll(0,0)
  };

  handleGoToPage = () => {
    const {

    limit,
    count

    } = this.props;

    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    // use Math.abs for strict equality ===  to work and safe guard against negative value from user
    if (Math.abs(this.state.pageO) === 0) {
    return; 
    }

    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)

    this.props.fetchAdByDealer(values, limit, (page * limit));
    window.scroll(0,0)

    }

  renderPaginator() {
    if (this.props.adByDealer.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }


  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }

//^^^^^^^^^^^^^^^^^^^^^^^^^
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//^^^^^^^^^^^^^^^^^^^^
  render() {

    return (

  <div className='maindiv-820'>
  <div >
  

  <div>
    {/**/}
<div className='cashbook-H box-shadow'>

<div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.handleSearchAdByDealer}>
      <span className='icon-button border1' > <Icon name='search' /></span >
    </div>



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{this.props.count}{' '} Advertisements
</div>
</div>


</div>
{/**/}
  
  
      {/*  */}
  {this.renderLoading()}
  {this.props.adByDealer.map(this.renderList)}

  {/*  */}
<div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()}
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>
{/*  */}
  <br/><br/>
  <div></div>
  <div></div>
  </div>
  <div className='nodisplay'>
  {/* side grid space*/}
  </div>


  </div>
  </div>

    );
  }
}


const mapStateToProps = ({ dealerSuggesteds, auth, form }) => {
  const { fetching,limit, offset, count } = dealerSuggesteds;
 
  return {
    auth,
    userA: auth.userA,
    fetching,
    form: form.SearchAdByDealer,
    limit,
    offset,
    count,
    adByDealer: dealerSuggesteds.adByDealer,

  };
};

export default connect(
  mapStateToProps,
  {fetchAdByDealer,openModal,getUserForAuth}
)(AdByDealerList);
