import React, { Component } from 'react';
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";
import { Menu,  Button, 
   
  Icon,
  Sidebar} from "semantic-ui-react";
  
  import {getUserForAuth, logoutUser,hideSidebar } from "../../actions/authAction";
  import { openModal } from "../../actions/modalAction";



class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {menuVisible: false,modalOpenL: false };
  }

  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      if(this.props.auth.isAuthenticated) {this.props.getUserForAuth();}
      //window.scroll(0,0)
    }
     }

     handleLoginModal = () => {
      this.props.openModal('LoginModal');
    };

onLogoutClick = () => {
  this.props.logoutUser();
};

  handleSidebarHide = () => this.setState({ menuVisible: false })

//#####################################
handleSearchMyPropResiSaleFH = () => {
  this.props.openModal("SearchMyPropResiSaleModalFH");
};

//########################################



    render () {
		 const { isAuthenticated, } = this.props.auth;
		const renderLoginLogoutButton=()=>{
      return isAuthenticated ? (

          <Button onClick={this.onLogoutClick}
          color='red'
          size="mini"
          ><Icon name="power" />Sign Out</Button>
        ) : (
          <Button onClick={this.handleLoginModal}
          color='teal'
          size="mini"
          ><Icon name="power" />Sign In</Button>
        )}

        //########################################
        const userA=this.props.userA !== null && this.props.userA
        const renderLink=() => {

         if(isAuthenticated) {         
         return (
           <>
         <div className='sidebar-border-divider px-0' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue',}}>
            RESIDENTIAL PROPERTY
          </div>

          <div className='sidebar-border-divider sidebar-div-link'>
          <Link to={'/propresi/PropResiCreate'} >
          <b>Create</b>
          </Link>
          </div>

          <div className='sidebar-border-divider sidebar-div-link'>
          <Link to={'/propresi/PropResiSaleSearchList'} >
          <b>SaleList</b>
          </Link>
          </div>

          <div className='sidebar-border-divider sidebar-div-link'>
          <Link to={'/propresi/PropResiRentSearchList'} >
          <b>RentList</b>
          </Link>
          </div>

          <div className='sidebar-border-divider sidebar-div-link'>
          <Link to={'/propresi/BuilderFloorSaleSearchList'} >
          <b>BuilderFloorSaleList</b>
          </Link>
          </div>

          <div className='sidebar-border-divider sidebar-div-link'>
          <Link to={'/propresi/PropResiAllSearchList'} >
          <b>All Properties</b>
          </Link>
          </div>

          <div className='sidebar-border-divider px-0' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
            COMMERCIAL PROPERTY
          </div>

          <div className='sidebar-border-divider sidebar-div-link'>
          <Link to={'/propcom/PropComCreate'} >
          <b>Create</b>
          </Link>
          </div>

          <div className='sidebar-border-divider sidebar-div-link'>
          <Link to={'/propcom/PropComSaleSearchList'} >
          <b>SaleList</b>
          </Link>
          </div>

          <div className='sidebar-border-divider sidebar-div-link'>
          <Link to={'/propcom/PropComRentSearchList'} >
          <b>RentList</b>
          </Link>
          </div>


        <div className='sidebar-border-divider px-0' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
          RESIDENTIAL PROJECT
        </div>

        <div className='sidebar-border-divider sidebar-div-link'>
        <Link to={'/projectR/ProjectRCreate'} >
        <b>Create</b>
        </Link>
        </div>

        <div className='sidebar-border-divider sidebar-div-link'>
        <Link to={'/projectR/ProjectRSearchList'} >
        <b>All Project</b>
        </Link>
        </div>


      <div className='sidebar-border-divider px-0' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
        COMMERCIAL PROJECT
      </div>

      <div className='sidebar-border-divider sidebar-div-link'>
      <Link to={'/projectC/ProjectCCreate'} >
      <b>Create</b>
      </Link>
      </div>

      <div className='sidebar-border-divider sidebar-div-link'>
      <Link to={'/projectC/ProjectCSearchList'} >
      <b>All Project</b>
      </Link>
      </div>


      <div className='sidebar-border-divider px-0' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue',}}>
        RESIDENTIAL REQUIREMENT
      </div>

      <div className='sidebar-border-divider sidebar-div-link'>
      <Link to={'/reqresi/ReqResiCreate'} >
      <b>Create</b>
      </Link>
      </div>

      <div className='sidebar-border-divider sidebar-div-link'>
      <Link to={'/reqresi/ReqResiSearchList'} >
      <b>All Requirement</b>
      </Link>
      </div>



      <div className='sidebar-border-divider px-0' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
        COMMERCIAL REQUIREMENT
      </div>

      <div className='sidebar-border-divider sidebar-div-link'>
      <Link to={'/reqcom/ReqComCreate'} >
      <b>Create</b>
      </Link>
      </div>

      <div className='sidebar-border-divider sidebar-div-link'>
      <Link to={'/reqcom/ReqComSearchList'} >
      <b>All Requirement</b>
      </Link>
      </div>

          {/*  */}
          <div className='sidebar-border-divider px-0' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
            DEALERS
            </div>


            <div className='sidebar-border-divider sidebar-div-link'>
            <Link to={'/register'} >
            <b>Create</b>
            </Link>
            </div>

            <div className='sidebar-border-divider sidebar-div-link'>
            <Link to={'/company/DealerSearchList'} >
            <b>All Dealers</b>
            </Link>
            </div>
            <div className='sidebar-border-divider sidebar-div-link'>
            <Link to={'/company/UserList'} >
            <b>All Users</b>
            </Link>
            </div>

            {/*  */}
          <div className='sidebar-border-divider px-0' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
            BUILDERS
            </div>


            <div className='sidebar-border-divider sidebar-div-link'>
            <Link to={'/registerBuilder'} >
            <b>Create</b>
            </Link>
            </div>

            <div className='sidebar-border-divider sidebar-div-link'>
            <Link to={'/builder/BuilderSearchList'} >
            <b>All Builders</b>
            </Link>
            </div>
            
          {/*  */}
          <div className='sidebar-border-divider px-0' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
            STATIC ADVERTISEMENT
            </div>


            <div className='sidebar-border-divider sidebar-div-link'>
            <Link to={'/static/StaticCreate'} >
            <b>Create</b>
            </Link>
            </div>

            <div className='sidebar-border-divider sidebar-div-link'>
            <Link to={'/static/StaticSearchList'} >
            <b>All Advertisement</b>
            </Link>
            </div>
            {/*  */}
            <div className='sidebar-border-divider px-0' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
            CREDIT ADDED
            </div>

            <div className='sidebar-border-divider sidebar-div-link'>
            <Link to={'/activity/CreditAddSearchList'} >
            <b>All Credit Added</b>
            </Link>
            </div>
          {/*  */} 
          <div className='sidebar-border-divider px-0' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
            APP PARAMETER
            </div>

            <div className='sidebar-border-divider sidebar-div-link'> 
          <Link to="/appParameter/AppParameterDetail" >
          <b>App Parameter Detail</b><span className='icon-angle' ><Icon name="angle right" /></span>
          </Link>
          </div>

          <div className='sidebar-border-divider sidebar-div-link'> 
          <Link to="/appParameter/URLCreate" >
          <b>Create URL</b><span className='icon-angle' ><Icon name="angle right" /></span>
          </Link>
          </div>

          <div className='sidebar-border-divider sidebar-div-link'> 
          <Link to="/entries/DeleteEntries" >
          <b>Delete Entries</b><span className='icon-angle' ><Icon name="angle right" /></span>
          </Link>
          </div>

          </>
)
      }
        }


//########################################
        return (
            <div style={{marginTop:"50px",}}>

            <Sidebar.Pushable >
            <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            // inverted
            vertical
            visible={this.props.visible}
            onClick={this.props.hideSidebar  }

          >
            <br/>
            <div style={{minWidth:'300px'}}>
               {renderLoginLogoutButton()}
            </div>
          <br/>
          {/*  */}
          {renderLink()}
          {/*  */}

<br/><br/>

              </Sidebar>
          <Sidebar.Pusher
          style={{minHeight:'100vh'}}
          >
          {/* <Button
          style={{color:"SlateBlue",marginLeft:"15px",marginTop:"10px",}}
          inverted
          onClick={() => this.setState({ menuVisible: !this.state.menuVisible })}
          color='blue'
          >
          <Icon name="sidebar" />
          </Button> */}
<div>
         
    </div>
        {this.props.children}
         </Sidebar.Pusher>
            </Sidebar.Pushable>

            </div>
        )
    }
}
const mapStateToProps = state => ({
  auth: state.auth,
  visible: state.auth.visible,
  userA: state.auth.userA,
});

export default connect(
  mapStateToProps,
  {getUserForAuth, logoutUser,hideSidebar,openModal } )(Layout);
